import { useEffect, useState } from "react";
import DaySchedulings from "./DaySchedulings";
import { Scheduling } from "../../../../models/schedule";
import { getMaintenanceDaySchedule } from "../../../../services/consultantScheduleService";

interface INextSchedulingsProps {
	now: Date;
	consultantMonthSchedule: Scheduling[];
}

const NextSchedulings = ({ now, consultantMonthSchedule }: INextSchedulingsProps) => {

	const [tomorrow, ] = useState<Date>(new Date(now.getTime() + 24 * 60 * 60 * 1000));

	const [todayTasks, setTodayTasks] = useState<Scheduling[]>([]);
	const [tomorrowTasks, setTomorrowTasks] = useState<Scheduling[]>([]);

	useEffect(() => {
		const fetchDateTasks = async () => {
			const todaySchedule = await getMaintenanceDaySchedule(now, consultantMonthSchedule);
			const tomorrowSchedule = await getMaintenanceDaySchedule(tomorrow, consultantMonthSchedule);
			
			setTodayTasks(todaySchedule?.schedulings || []);
			setTomorrowTasks(tomorrowSchedule?.schedulings || []);
		}
		
		fetchDateTasks();
	}, [consultantMonthSchedule]);

	return (
		<div>
			<DaySchedulings dayTitle="Hoje" tasks={todayTasks} />
			<DaySchedulings dayTitle="Amanhã" tasks={tomorrowTasks} />
		</div>
	);
}

export default NextSchedulings;