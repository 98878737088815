import { useState } from "react";
import random from "lodash/fp/random";
import isEmpty from "lodash/fp/isEmpty";
import TreeCategory from "@rio-cloud/rio-uikit/TreeCategory";
import Tree from "@rio-cloud/rio-uikit/Tree";
import "./styles.scss";

const CATEGORY_FILTER = "filter";
const CATEGORY_TRUCKS = "trucks";

const getTruckGroups = (): any[] => [
  {
    id: "truck-6ba7c2ec-8bd4-4470-98a4-313c9d33e8d5",
    name: "Truck Group North",
  },
  {
    id: "truck-b40aabcf-01f8-4be2-bc3d-9157cbdb395f",
    name: "Truck Group East",
  },
  {
    id: "truck-722a5f86-eb6c-46cf-83da-3f8dbfbcca5e",
    name: "Truck Group South",
  },
  {
    id: "truck-0250a8aa-721e-40b4-b1ea-7908acddfdf4",
    name: "Truck Group West",
    className: "foobar",
  },
  {
    id: "unassigned",
    name: "Veículos não atribuídos",
    position: "last",
  },
];

const trailerGroups: any[] = [
  {
    id: "6ba7c2ec-8bd4-4470-98a4-313c9d33e8d5",
    name: "AcceptanceTest Brasil",
  },
  {
    id: "6ba7c2ec-8bd4-4470-98a4-313c9d33e822",
    name: "Concórdia Logística S/A",
  },
  {
    id: "6ba7c2ec-8bd4-4470-98a4-313c9d33e821",
    name: "Dibracam Com Ltda",
  },
  {
    id: "6ba7c2ec-8bd4-4470-98a4-313c9d33e820",
    name: "Frota da Raflsia",
  },
  {
    id: "6ba7c2ec-8bd4-4470-98a4-313c9d33e819",
    name: "Frota RP",
  },
  {
    id: "6ba7c2ec-8bd4-4470-98a4-313c9d33e818",
    name: "Rochina Transportes",
  },
];

const trailer: any[] = [
  {
    id: "110191ac-d06d-4567-b13a-7b7fd85d9731",
    name: "Trailer 03",
    type: "trailer",
    groupIds: ["6ba7c2ec-8bd4-4470-98a4-313c9d33e822"],
  },
  {
    id: "110191ac-d06d-4567-b13a-7b7fd85d9740",
    name: "Trailer 04",
    type: "trailer",
    groupIds: ["6ba7c2ec-8bd4-4470-98a4-313c9d33e822"],
  },
  {
    id: "16807251-95d2-4d73-afd0-4fbd0eadebf44",
    name: "Trailer 01",
    type: "trailer",
    groupIds: ["6ba7c2ec-8bd4-4470-98a4-313c9d33e820"],
  },
  {
    id: "16807251-95d2-4d73-afd0-4fbd0eadebf699",
    name: "Trailer 02",
    type: "trailer",
    groupIds: ["66ba7c2ec-8bd4-4470-98a4-313c9d33e820"],
  },
  {
    id: "110191ac-d06d-4567-b13a-7b7fd85d97315",
    name: "Trailer 03",
    type: "trailer",
    groupIds: ["6ba7c2ec-8bd4-4470-98a4-313c9d33e821"],
  },
  {
    id: "110191ac-d06d-4567-b13a-7b7fd85d97408",
    name: "Trailer 04",
    type: "trailer",
    groupIds: ["6ba7c2ec-8bd4-4470-98a4-313c9d33e821"],
  },
  {
    id: "16807251-95d2-4d73-afd0-4fbd0eadebf42",
    name: "Trailer 01",
    type: "trailer",
    groupIds: ["6ba7c2ec-8bd4-4470-98a4-313c9d33e8d5"],
  },
  {
    id: "16807251-95d2-4d73-afd0-4fbd0eadebf644",
    name: "Trailer 02",
    type: "trailer",
    groupIds: ["6ba7c2ec-8bd4-4470-98a4-313c9d33e819"],
  },
  {
    id: "16807251-95d2-4d73-afd0-4fbd0eadebf4200",
    name: "Trailer 01",
    type: "trailer",
    groupIds: ["6ba7c2ec-8bd4-4470-98a4-313c9d33e818"],
  },
  {
    id: "16807251-95d2-4d73-afd0-4fbd0eadebf6",
    name: "Trailer 02",
    type: "trailer",
    groupIds: ["unassigned"],
  },
];

const getTrucks = (namePrefix = "Vehicle-"): any[] => {
  const randomTrucks = Array.from({ length: random(100, 200) }, (_, index) => ({
    id: `010191ac-d06d-4567-b13a-7b7fd85d97${index}`,
    name: `${namePrefix}${random(1000, 9999)}`,
    info: `M-AN 1${String(index).padStart(3, "0")}`,
    type: "truck",
    // groupIds: [faker.helpers.arrayElement(groups).id],
  }));
  return [...randomTrucks, ...trailer];
};

const truckGroupsWithEmptyGroup: any[] = [
  ...getTruckGroups(),
  {
    id: "0350a8aa-721e-40b4-b1ea-7908acddfdf4",
    name: "My Empty Group",
    disabled: true,
  },
  {
    id: "truck-0250a8aa-721e-40b4-b1ea-7908acddfdf1",
    name: "Truck Group West 2",
  },
  {
    id: "truck-0250a8aa-721e-40b4-b1ea-7908acddfdf2",
    name: "Truck Group West 3",
  },
  ...trailerGroups,
];

const defaultExpandedTruckGroups: string[] = [
  "6ba7c2ec-8bd4-4470-98a4-313c9d33e8d5",
];

const AssetTreeSidebar = () => {
  const [trucks, setTrucks] = useState<any[]>(getTrucks());
  const [truckGroups, setTruckGroups] = useState<any[]>(
    truckGroupsWithEmptyGroup
  );
  const [selectedTruckGroupIds, setSelectedTruckGroupIds] = useState<string[]>(
    []
  );
  const [selectedTruckIds, setSelectedTruckIds] = useState<string[]>([]);
  const [expandedTruckGroups, setExpandedTruckGroups] = useState(
    defaultExpandedTruckGroups
  );
  const [searchValue, setSearchValue] = useState("");
  const [showEmptyGroups, setShowEmptyGroups] = useState(false);
  const [showAssetGroups, setShowAssetGroups] = useState(true);

  const handleSelectTruck = ({ items, groups }: any) => {
    setSelectedTruckIds(items);
    setSelectedTruckGroupIds(groups);
  };

  const handleExpandTruckGroups = (newExpandedTruckGroups: string[]) =>
    setExpandedTruckGroups(newExpandedTruckGroups);

  const handleUpdateSearch = (value: string) => setSearchValue(value);

  return (
    <div className="display-flex flex-wrap gap-25 overflow-auto">
      <div className="w-full">
        <TreeCategory
          key={CATEGORY_FILTER}
          id={CATEGORY_FILTER}
          label="Filter"
          icon="rioglyph-filter"
        >
          {" "}
        </TreeCategory>

        <TreeCategory
          key={CATEGORY_TRUCKS}
          id={CATEGORY_TRUCKS}
          label="Assets"
          icon="rioglyph-truck"
          hasSelection={
            !isEmpty(selectedTruckIds) || !isEmpty(selectedTruckGroupIds)
          }
        >
          <Tree
            groups={showAssetGroups ? truckGroups : []}
            items={trucks}
            expandedGroups={expandedTruckGroups}
            onExpandGroupsChange={handleExpandTruckGroups}
            selectedGroups={selectedTruckGroupIds}
            selectedItems={selectedTruckIds}
            onSelectionChange={handleSelectTruck}
            searchPlaceholder="Buscar veículo"
            onSearchChange={handleUpdateSearch}
            showEmptyGroups={showEmptyGroups}
          />
        </TreeCategory>
      </div>
    </div>
  );
};

export default AssetTreeSidebar;
