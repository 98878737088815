"use client";
import React, { useState } from "react";
import Page from "../../templates/Page";
import Checkbox from "@rio-cloud/rio-uikit/Checkbox";
import Multiselect from "@rio-cloud/rio-uikit/Multiselect";
import Notification from "@rio-cloud/rio-uikit/Notification";
import Switch from "@rio-cloud/rio-uikit/Switch";
import Select from "@rio-cloud/rio-uikit/Select";
import RadioButton from "@rio-cloud/rio-uikit/RadioButton";
import Tooltip from "@rio-cloud/rio-uikit/Tooltip";
import OverlayTrigger from "@rio-cloud/rio-uikit/OverlayTrigger";
import {
  format,
  startOfWeek,
  endOfWeek,
  addDays,
  subWeeks,
  addWeeks,
} from "date-fns";
import "./styles.scss";

interface Motorista {
  nome: string;
  cnh: string;
  email: string;
  telefone: string;
}

export const MyAppointments = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [showSearchConcessionaria, setShowSearchConcessionaria] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState<string>("option1");
  const [activeTab, setActiveTab] = useState<
    "favoritas" | "novaConcessionaria"
  >("favoritas");
  const [switchStates, setSwitchStates] = useState<{
    [key: string]: boolean;
  }>({
    switch1: true,
    switch2: true,
    switch3: false,
    switch4: false,
    switch5: false,
    switch6: true,
  });
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<string | null>(null);
  const [currentWeek, setCurrentWeek] = useState(new Date());
  const [motorista, setMotorista] = useState<Motorista>({
    nome: "",
    cnh: "",
    email: "",
    telefone: "",
  });
  const [isChecked, setIsChecked] = useState(false);
  const [showComments, setShowComments] = useState(false);

  const motoristaMock: Motorista = {
    nome: "Henrique de Sá",
    cnh: "00123456789",
    email: "h.sa@volkswagen.com.br",
    telefone: "(11) 91234-5678",
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);

    if (!isChecked) {
      setMotorista(motoristaMock);
    } else {
      // Reseta os campos ao desmarcar a checkbox
      setMotorista({
        nome: "",
        cnh: "",
        email: "",
        telefone: "",
      });
    }
  };

  const consultants = [
    { id: "none", name: "Sem preferência" },
    { id: "lays-silva", name: "Lays Silva" },
    { id: "samuel-santos", name: "Samuel Santos" },
    { id: "leonardo-fagundes", name: "Leonardo Fagundes" },
  ];

  const timeSlots = [
    "7:00",
    "7:30",
    "8:00",
    "8:30",
    "9:00",
    "9:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
  ];

  const handleDateSelect = (date: string) => {
    setSelectedDate(date);
  };

  const handlePreviousWeek = () => {
    setCurrentWeek((prev) => subWeeks(prev, 1));
  };

  const handleNextWeek = () => {
    setCurrentWeek((prev) => addWeeks(prev, 1));
  };

  const generateWeekDays = () => {
    const start = startOfWeek(currentWeek, { weekStartsOn: 0 }); // Start on Sunday
    const end = endOfWeek(currentWeek, { weekStartsOn: 0 });
    const days = [];
    let day = start;

    while (day <= end) {
      days.push({
        date: day,
        formatted: format(day, "d"),
        dayName: format(day, "EEE"),
      });
      day = addDays(day, 1);
    }

    return days;
  };

  const weekDays = generateWeekDays();

  const handleSlotSelect = (slot: string) => {
    setSelectedSlot(slot);
  };

  const nextStep = () => {
    if (currentStep < 4) setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const options = [
    {
      id: "1",
      label: "Inspeção visual do veículo",
      icon: <img src="assets/images/check-icon.svg" alt="" />,
    },
    {
      id: "2",
      label: "Ajustes básicos (ex: regulagem de luzes e espelhos)",
      icon: <img src="assets/images/check-icon.svg" alt="" />,
    },
    {
      id: "3",
      label: "Verificação de torque de porcas de roda",
      icon: <img src="assets/images/check-icon.svg" alt="" />,
    },
    {
      id: "4",
      label: "Checagem de níveis de fluidos",
      icon: <img src="assets/images/check-icon.svg" alt="" />,
    },
  ];

  const services = [
    {
      id: "1",
      label: "Análise de fluído (Predic) | R$ 50,00",
      value: 50,
    },
    {
      id: "2",
      label: "Ajustes básicos (ex: regulagem de luzes e espelhos)",
      value: 100,
    },
    {
      id: "3",
      label: "Verificação de torque de porcas de roda",
      value: 150,
    },
    {
      id: "4",
      label: "Checagem de níveis de fluidos",
      value: 200,
    },
  ];

  const handleChange = (key: string) => {
    const newState = !switchStates[key];
    Notification.info(`Checked: ${newState}`);
    setShowComments(!showComments);
    setSwitchStates({
      ...switchStates,
      [key]: newState,
    });
  };

  const concessionarias = [
    {
      nome: "Bravo",
      endereco:
        "Av. Dep. Luis Eduardo Magalhaes, 1095 - Humildes, Feira De Santana - BA, 44135-000",
      favorita: true,
    },
    {
      nome: "Buriti",
      endereco:
        "Av. Celso Mazutti, 2735 - Jardim America, Vilhena - RO, 76980-970",
      favorita: true,
    },
    {
      nome: "Granfer",
      endereco:
        "Rodovia BR 163, 2.100 - Parque das Nações, Dourados - MS, 79841-550",
      favorita: true,
    },
    {
      nome: "Maggi",
      endereco:
        "Rodovia BR 116 - Km 439,5 - Arapongal, Registro - SP, 04135-000",
      selecionada: true,
      favorita: true,
    },
  ];

  const estados = [
    { id: "1", label: "São Paulo" },
    { id: "2", label: "Bahia" },
    { id: "3", label: "Rio de Janeiro" },
    { id: "4", label: "Rio Grande do Sul" },
    { id: "5", label: "Acre" },
  ];

  const handleSelectionChange = (value: string) => {
    setSelectedOption(value);
  };

  const favoritas = concessionarias.filter((c) => c.favorita);
  const novaConcessionaria = concessionarias.filter((c) => !c.favorita);

  return (
    <div className="mySchedule">
      <Page>
        <div className="min-h-screen w-full flex justify-center">
          <div className="w-full bg-white">
            {/* Renderização condicional do conteúdo de cada step */}
            <div className="stepsSchedule">
              {currentStep === 1 && (
                <>
                  <div className="scheduling-page p-10">
                    <div className="scheduling-page__header">
                      <h2 className="text-size-h2 text-color-black font-semibold text-uppercase">
                        1. Veículos e motoristas
                      </h2>
                      <p className="text-color-status-resting text-size-16 padding-top-10">
                        Preencha abaixo os dados dos veículos e dos motoristas
                      </p>
                    </div>

                    <div className="border rounded padding-15 margin-top-20 scheduling-page__fieldset">
                      <h6 className="text-bold text-size-16">
                        Informações do veículo
                      </h6>
                      <div className="border rounded padding-15 margin-top-20 scheduling-page__fieldset">
                        <div className="flex items-center gap-4">
                          <img
                            src="/assets/images/vehicle-thumb.png"
                            alt="Vehicle"
                            className="w-20 h-20 rounded"
                          />

                          <div className="flex-1 padding-left-10">
                            <p className="text-color-black text-size-16 font-medium m-0">
                              Meteor VW29.530
                            </p>
                            <p className="text-color-status-resting text-size-14 m-0">
                              Chassi - 9536G8241LR021010
                            </p>
                          </div>

                          <div className="flex gap-6 margin-bottom-20">
                            <span className="px-2 py-1 green-tag">RIO</span>
                            <span className="px-2 py-1 green-tag">
                              Em Garantia
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-between w-full gap-6 mt-5">
                        <div className="form-group form-group-lg w-1/3">
                          <label htmlFor="placa">Placa do veículo</label>
                          <div className="input-group">
                            <input
                              id="placa"
                              className="form-control"
                              type="text"
                              placeholder="Digite a placa do veículo"
                            />
                          </div>
                        </div>

                        <div className="form-group form-group-lg w-1/3">
                          <label htmlFor="placa">Modelo do veículo</label>
                          <div className="input-group">
                            <input
                              id="placa"
                              className="form-control"
                              type="text"
                              value="Meteor VW29.530"
                              disabled
                            />
                          </div>
                        </div>

                        <div className="form-group form-group-lg w-1/3">
                          <label htmlFor="placa">Ano do veículo</label>
                          <div className="input-group">
                            <input
                              id="placa"
                              className="form-control"
                              type="text"
                              value="2024"
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-between w-full gap-6">
                        <div className="form-group form-group-lg w-1/2">
                          <label htmlFor="placa">Horímetro</label>
                          <div className="input-group">
                            <input
                              id="placa"
                              className="form-control"
                              type="text"
                              value="147:26:59"
                              disabled
                            />
                          </div>
                        </div>

                        <div className="form-group form-group-lg w-1/2">
                          <label htmlFor="placa">Odômetro</label>
                          <div className="input-group">
                            <input
                              id="placa"
                              className="form-control"
                              type="text"
                              value="5.032 km"
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-column">
                        <Checkbox
                          id="checkbox-motorista"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        >
                          Eu sou o motorista
                        </Checkbox>
                        <div className="flex justify-between w-full gap-6 mt-5">
                          <div className="form-group form-group-lg w-1/2">
                            <label htmlFor="motorista">
                              Nome do motorista*
                            </label>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <span className="rioglyph rioglyph-search text-color-black"></span>
                              </span>
                              <input
                                id="motorista"
                                className="form-control"
                                type="text"
                                placeholder="Digite o nome do motorista"
                                value={motorista.nome}
                                disabled={isChecked}
                              />
                            </div>
                          </div>

                          <div className="form-group form-group-lg w-1/2">
                            <label htmlFor="cnh">CNH do motorista</label>
                            <div className="input-group">
                              <input
                                id="cnh"
                                className="form-control"
                                type="text"
                                placeholder="Digite a CNH do motorista"
                                value={motorista.cnh}
                                disabled={isChecked}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-between w-full gap-6">
                          <div className="form-group form-group-lg w-1/2">
                            <label htmlFor="emailMotorista">
                              E-mail do motorista*
                            </label>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <span className="rioglyph rioglyph-envelope text-color-black"></span>
                              </span>
                              <input
                                id="emailMotorista"
                                className="form-control"
                                type="text"
                                placeholder="Digite o e-mail do motorista"
                                value={motorista.email}
                                disabled={isChecked}
                              />
                            </div>
                          </div>

                          <div className="form-group form-group-lg w-1/2">
                            <label htmlFor="telefoneMotorista">
                              Telefone do motorista*
                            </label>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <span className="rioglyph rioglyph-earphone text-color-black"></span>
                              </span>
                              <input
                                id="telefoneMotorista"
                                className="form-control"
                                type="text"
                                placeholder="Digite o telefone do motorista"
                                value={motorista.telefone}
                                disabled={isChecked}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="border rounded padding-15 margin-top-20 scheduling-page__fieldset">
                      <h6 className="text-bold text-size-16">
                        Revisões com base no intervalo de km e/ou tempo do
                        veículo e ações de oficina (se houver)
                      </h6>
                      <div className="info-group">
                        <p className="text-color-status-resting text-size-16 padding-top-10 padding-bottom-5">
                          Revisões disponíveis
                        </p>

                        <div className="avalilable-revisions">
                          <Multiselect
                            placeholder="Selecione"
                            options={options}
                            onChange={(response: any) =>
                              Notification.info(JSON.stringify(response))
                            }
                          />
                        </div>
                      </div>

                      <div className="info-group">
                        <p className="text-color-status-resting text-size-16 padding-top-10 padding-bottom-5">
                          Ações de oficina
                        </p>

                        <div className="avalilable-revisions">
                          <Checkbox>
                            <div className="info-alert">
                              <span className="info-alert__tag">
                                Ação de oficina
                              </span>
                              <span className="info-alert__text">
                                Seu veículo possui uma ação pendente. Agende o
                                reparo o quanto antes.
                              </span>
                              <OverlayTrigger
                                key={crypto.randomUUID()}
                                overlay={
                                  <Tooltip id="tooltip" allowOnTouch>
                                    <div className="tooltip-card flex flex-column padding-10 gap-6">
                                      <h3 className="text-size-20 font-bold items-start text-left">
                                        Ação de oficina
                                      </h3>
                                      <ul>
                                        <li>
                                          Substituição do cinto de segurança
                                        </li>
                                      </ul>
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <span className="rioglyph rioglyph-exclamation"></span>
                              </OverlayTrigger>
                            </div>
                          </Checkbox>
                        </div>
                      </div>
                    </div>

                    <div className="border rounded padding-15 margin-top-20 scheduling-page__fieldset">
                      <div className="flex justify-between">
                        <h6 className="text-bold text-size-16">
                          Contratação de serviços
                        </h6>
                        <div className="flex items-center padding-left-10 padding-right-10 total-value">
                          <span className="font--bold text-size-14 font-bold total-value__label">
                            Total
                          </span>
                          <span className="font-bold text-size-14 padding-left-5 padding-right-5 margin-left-10 total-value__value">
                            R$ 4.250,00
                          </span>
                        </div>
                      </div>

                      <div className="info-group">
                        <p className="text-color-status-resting text-size-16 padding-top-10 padding-bottom-5">
                          Adicionar outros serviços
                        </p>

                        <div className="add-services">
                          <Multiselect
                            placeholder="Selecionar serviços"
                            options={services.map((service) => ({
                              id: service.id,
                              label: service.label,
                              value: service.value,
                            }))}
                            className="select-icon"
                            onChange={(response: any) =>
                              Notification.info(JSON.stringify(response))
                            }
                          />
                          <div className="services-cost">
                            <span className="font-bold text-size-14 padding-left-5 padding-right-5 margin-left-10 total-value__value">
                              R$ 0,00
                            </span>
                          </div>
                          <div className="show-comments margin-top-15 flex justify-end show-comments">
                            <Switch
                              onChange={() => handleChange("switch4")}
                              checked={switchStates.switch4}
                              labelPosition="right"
                              color="secondary"
                            >
                              <span className="text-size-14 text-color-status-resting">
                                comentário
                              </span>
                            </Switch>
                          </div>
                          {showComments && (
                            <div className="text-comments margin-top-50">
                              <textarea
                                className="form-control"
                                placeholder="Comentário..."
                              ></textarea>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* <div className="info-group">
                        <p className="text-color-status-resting text-size-16 padding-top-10 padding-bottom-5">
                          Cesta de peças
                        </p>

                        <div className="add-services cart-row">
                          <Multiselect
                            placeholder="Compre peças para este agendamento"
                            options={services.map((service) => ({
                              id: service.id,
                              label: service.label,
                              value: service.value,
                            }))}
                            className="select-icon"
                            onChange={(response: any) =>
                              Notification.info(JSON.stringify(response))
                            }
                          />
                          <div className="services-cost">
                            <span className="font-bold text-size-14 padding-left-5 padding-right-5 margin-left-10 total-value__value">
                              R$ 0,00
                            </span>
                          </div>
                        </div>
                      </div> */}
                    </div>

                    <div className="border rounded padding-15 margin-top-20 scheduling-page__fieldset">
                      <div className="flex justify-between">
                        <h6 className="text-bold text-size-16">
                          Observações gerais
                        </h6>
                      </div>

                      <div className="info-group">
                        <div className="add-services">
                          <div className="text-comments margin-top-10">
                            <textarea
                              className="form-control"
                              placeholder="Detalhe o que está acontecendo com o veículo..."
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="page-info flex justify-between padding-15">
                    <span className="text-color-status-resting font-size-14">
                      Passo{" "}
                      <strong className="text-color-danger">
                        {currentStep}
                      </strong>{" "}
                      de 4
                    </span>
                    <span className="text-color-status-resting font-size-14">
                      Informações do veículo e serviços
                    </span>
                  </div>
                  <div className="flex justify-between navigation-buttons step-1">
                    <button
                      onClick={prevStep}
                      disabled={currentStep === 1}
                      className={`px-4 py-2 rounded-lg font-semibold button-color ${
                        currentStep === 1
                          ? "cursor-not-allowed"
                          : "hover:bg-gray-300"
                      }`}
                    >
                      <span className="rioglyph rioglyph-arrow-left text-size-16"></span>{" "}
                      Voltar
                    </button>
                    {currentStep < 4 ? (
                      <button
                        onClick={nextStep}
                        className="px-4 py-2 btn btn-primary"
                      >
                        Próxima etapa
                      </button>
                    ) : (
                      <button className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600">
                        Enviar
                      </button>
                    )}
                  </div>
                </>
              )}
              {currentStep === 2 && (
                <div className="flex h-screen chooseConcessionaria">
                  <div className="w-1/3 overflow-y-auto p-10 padding-right-20">
                    <h2 className="text-size-h2 text-color-black font-semibold text-uppercase">
                      2. ESCOLHA SUA CONCESSIONÁRIA
                    </h2>
                    <p className="text-color-status-resting text-size-16 padding-top-10">
                      Escolha sua concessionária favorita ou selecione outra da
                      lista para realizar os serviços.
                    </p>

                    <div className="flex border-b mb-10 mt-10">
                      <button
                        onClick={() => setActiveTab("favoritas")}
                        className={`px-4 py-2 font-medium ${
                          activeTab === "favoritas"
                            ? "text-color-primary border-b-2 border-color-primary"
                            : "text-gray-600 hover-text-color-primary"
                        }`}
                      >
                        Favoritas
                      </button>
                      <button
                        onClick={() => setActiveTab("novaConcessionaria")}
                        className={`px-4 py-2 font-medium ${
                          activeTab === "novaConcessionaria"
                            ? "text-color-primary border-b-2 border-color-primary"
                            : "text-gray-600 hover-text-color-primary"
                        }`}
                      >
                        Nova concessionária
                      </button>
                    </div>

                    {activeTab === "favoritas" ? (
                      <ul className="flex flex-column gap-6">
                        {favoritas.map((concessionaria, index) => (
                          <li
                            key={index}
                            className={`flex items-center justify-between border card-concessionaria ${
                              concessionaria.selecionada
                                ? "selected"
                                : "border-gray-300"
                            }`}
                          >
                            <div className="flex flex-column w-full">
                              <div className="flex items-center gap-6 w-full border-top-none border-right-none border-left-none border-width-2">
                                <h3 className="flex items-center gap-6 font-semibold text-size-18 p-5">
                                  <img
                                    src="assets/images/icon-concessionaria.svg"
                                    alt=""
                                  />
                                  {concessionaria.nome}
                                </h3>

                                <button
                                  className={`text-red-500 hover:text-red-600 ${
                                    concessionaria.favorita
                                      ? "text-red-500"
                                      : "text-gray-400"
                                  }`}
                                >
                                  <span className="rioglyph rioglyph-heart text-size-16 text-color-danger"></span>
                                </button>
                                <Checkbox
                                  checked={concessionaria.selecionada}
                                />
                              </div>
                              <p className="text-size-14 padding-top-10 p-5">
                                {concessionaria.endereco}
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="new-concessionaria">
                        <div className="flex justify-between gap-6">
                          <div className="form-group w-1/2">
                            <label htmlFor="1">Selecionar estado (UF):</label>
                            <Select
                              id="1"
                              placeholder="Selecionar"
                              options={estados}
                              onChange={(item: any) =>
                                Notification.info(item?.label)
                              }
                              className="test-className"
                              btnClassName="test-btnClassName"
                              dropdownClassName="test-dropdownClassName"
                            />
                          </div>

                          <div className="form-group w-1/2">
                            <label htmlFor="1">Selecionar cidade:</label>
                            <Select
                              id="1"
                              placeholder="Selecionar"
                              options={estados}
                              onChange={(item: any) =>
                                Notification.info(item?.label)
                              }
                              className="test-className"
                              btnClassName="test-btnClassName"
                              dropdownClassName="test-dropdownClassName"
                            />
                          </div>
                        </div>

                        <div className="flex">
                          <div className="form-group w-full">
                            <label htmlFor="1">Selecionar concessionária</label>
                            <div
                              className="select-concessionaria cursor-pointer flex justify-between items-center gap-6 padding-left-10 padding-right-10"
                              onClick={() =>
                                setShowSearchConcessionaria(
                                  !showSearchConcessionaria
                                )
                              }
                            >
                              <div className="flex items-center gap-6">
                                <img src="assets/images/building.svg" alt="" />
                                <span className="text-color-gray">
                                  Selecionar concessionária
                                </span>
                              </div>
                              <span className="rioglyph rioglyph-chevron-down text-size-10"></span>
                            </div>

                            {showSearchConcessionaria && (
                              <div className="search-concessionaria">
                                <div className="form-group form-group-lg">
                                  <div className="input-group">
                                    <span className="input-group-addon">
                                      <span className="rioglyph rioglyph-search"></span>
                                    </span>
                                    <input
                                      id="input7"
                                      className="form-control"
                                      type="text"
                                      placeholder="Procurar concessionária"
                                    />
                                  </div>
                                </div>

                                {/* Opções de concessionárias */}
                                <div
                                  className={`concessionaria-options ${
                                    selectedOption === "option1"
                                      ? "selected"
                                      : ""
                                  }`}
                                >
                                  <RadioButton
                                    name="radios"
                                    id="optionsRadios1"
                                    value="option1"
                                    checked={selectedOption === "option1"}
                                    onChange={() =>
                                      handleSelectionChange("option1")
                                    }
                                  >
                                    <span className="text-size-14">
                                      <strong className="text-size-14">
                                        Dibracan
                                      </strong>
                                      <span className="card-km text-size-14 font-semibold">
                                        1 Km
                                      </span>
                                      Avenida Corifeu De Azevedo Marques, 345 -
                                      Buta...
                                    </span>
                                  </RadioButton>
                                </div>

                                <div
                                  className={`concessionaria-options ${
                                    selectedOption === "option2"
                                      ? "selected"
                                      : ""
                                  }`}
                                >
                                  <RadioButton
                                    name="radios"
                                    id="optionsRadios2"
                                    value="option2"
                                    checked={selectedOption === "option2"}
                                    onChange={() =>
                                      handleSelectionChange("option2")
                                    }
                                  >
                                    <span className="text-size-14">
                                      <strong className="text-size-14">
                                        Tiete
                                      </strong>
                                      <span className="card-km text-size-14 font-semibold">
                                        5 Km
                                      </span>
                                      Av.Marginal Direita Do R.Tiete, 700 - Vila
                                      Jaguara - Sã...
                                    </span>
                                  </RadioButton>
                                </div>

                                <div
                                  className={`concessionaria-options ${
                                    selectedOption === "option3"
                                      ? "selected"
                                      : ""
                                  }`}
                                >
                                  <RadioButton
                                    name="radios"
                                    id="optionsRadios3"
                                    value="option3"
                                    checked={selectedOption === "option3"}
                                    onChange={() =>
                                      handleSelectionChange("option3")
                                    }
                                  >
                                    <span className="text-size-14">
                                      <strong className="text-size-14">
                                        Maggi
                                      </strong>
                                      <span className="card-km text-size-14 font-semibold">
                                        32 Km
                                      </span>
                                      Rodovia Br 116 - Km 439,5 - Arapongas -
                                      Jundiaí
                                    </span>
                                  </RadioButton>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="info-and-nav">
                      <div className="page-info flex justify-between padding-15">
                        <span className="text-color-status-resting font-size-14">
                          Passo{" "}
                          <strong className="text-color-danger">
                            {currentStep}
                          </strong>{" "}
                          de 4
                        </span>
                        <span className="text-color-status-resting font-size-14">
                          Escolha sua concessionária
                        </span>
                      </div>
                      <div className="flex justify-between navigation-buttons">
                        <button onClick={prevStep}>
                          <span className="rioglyph rioglyph-arrow-left text-size-16"></span>{" "}
                          Voltar
                        </button>
                        {currentStep < 4 ? (
                          <button
                            onClick={nextStep}
                            className="px-4 py-2 btn btn-primary"
                          >
                            Próxima etapa
                          </button>
                        ) : (
                          <button className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600">
                            Enviar
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Coluna Direita - Mapa */}
                  <div className="w-2/3">
                    <iframe
                      className="w-full h-full"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345093745!2d144.9556523156167!3d-37.81720917975161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf5773c1f93ef0e58!2sGoogle!5e0!3m2!1sen!2sau!4v1614044075922!5m2!1sen!2sau"
                      allowFullScreen
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
              )}
              {currentStep === 3 && (
                <>
                  <div className="scheduling-page calendar-section p-10">
                    <div className="scheduling-page__header">
                      <h2 className="text-size-h2 text-color-black font-semibold text-uppercase">
                        3. Agendamento
                      </h2>
                      <p className="text-color-status-resting text-size-16 padding-top-10 font-semibold">
                        Concessionária:
                      </p>
                    </div>

                    <div className="flex items-center justify-between border card-concessionaria selected margin-top-10 margin-bottom-20">
                      <div className="flex flex-column w-full">
                        <div className="flex justify-between items-center gap-6 w-full border-top-none border-right-none border-left-none border-width-2">
                          <h3 className="flex items-center gap-6 font-semibold text-size-18 p-5">
                            <img
                              src="assets/images/icon-concessionaria.svg"
                              alt=""
                            />
                            Maggi
                          </h3>
                          <button className="padding-right-20">
                            <span className="rioglyph rioglyph-heart text-size-16"></span>
                          </button>
                        </div>
                        <p className="text-size-14 p-6">
                          Rodovia BR 116 - Km 439,5 - Arapongal, Registro - SP,
                          04135-000
                        </p>
                      </div>
                    </div>

                    <div className="mb-4">
                      <p className="text-color-status-resting text-size-16 padding-top-10 font-semibold">
                        Selecionar a data: *
                      </p>
                      <div className="calendar-row margin-top-10">
                        <div className="flex items-center justify-between mb-2 calendar-row__month">
                          <button onClick={handlePreviousWeek}>
                            <span className="rioglyph rioglyph-chevron-left text-color-status-available"></span>
                          </button>
                          <span className="text-size-16 text-color-status-resting font-semibold">
                            {format(currentWeek, "MMMM/yyyy")}
                          </span>
                          <button onClick={handleNextWeek}>
                            <span className="rioglyph rioglyph-chevron-right text-color-status-available"></span>
                          </button>
                        </div>
                        <div className="flex justify-between text-center p-2 calendar-row__days">
                          {weekDays.map(({ date, formatted, dayName }) => (
                            <button
                              key={formatted}
                              className={`flex flex-col items-center gap-5 p-2 w-12 h-16 rounded-lg text-sm transition-all ${
                                selectedDate === formatted
                                  ? "active-date text-white"
                                  : "hover:bg-gray-200"
                              }`}
                              onClick={() => handleDateSelect(formatted)}
                            >
                              <span className="text-size-12 text-gray-600 text-lowercase">
                                {dayName}
                              </span>
                              <span className="font-medium flex items-center justify-center text-size-14 day-card">
                                {formatted}
                              </span>
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="mb-6">
                      <p className="text-color-status-resting text-size-16 padding-top-10 font-semibold">
                        Selecionar o consultor: *
                      </p>
                      <div className="overflow-x-auto margin-top-20 consult-table">
                        <table className="w-full table-fixed border-collapse">
                          <thead>
                            <tr>
                              <th className="w-1/6 text-left p-2 border-b text-size-14">
                                Consultor(a)
                              </th>
                              {timeSlots.map((slot) => (
                                <th
                                  key={slot}
                                  className="w-1/24 text-center p-2 border-b text-size-14"
                                >
                                  {slot}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {consultants.map((consultant) => (
                              <tr key={consultant.id}>
                                <td className="p-2 border-b text-size-14 font-medium">
                                  {consultant.name}
                                </td>
                                {timeSlots.map((slot) => (
                                  <td
                                    key={slot}
                                    className="p-1 border-b text-center"
                                  >
                                    <RadioButton
                                      className="form-radio"
                                      name="time-slot"
                                      disabled={!selectedDate}
                                      checked={
                                        selectedSlot ===
                                        `${consultant.id}-${slot}`
                                      }
                                      onChange={() =>
                                        handleSlotSelect(
                                          `${consultant.id}-${slot}`
                                        )
                                      }
                                    />
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="page-info flex justify-between padding-15">
                    <span className="text-color-status-resting font-size-14">
                      Passo{" "}
                      <strong className="text-color-danger">
                        {currentStep}
                      </strong>{" "}
                      de 4
                    </span>
                    <span className="text-color-status-resting font-size-14">
                      Agendamento
                    </span>
                  </div>
                  <div className="flex justify-between navigation-buttons step-1">
                    <button onClick={prevStep}>
                      <span className="rioglyph rioglyph-arrow-left text-size-16"></span>{" "}
                      Voltar
                    </button>
                    {currentStep < 4 ? (
                      <button
                        onClick={nextStep}
                        className="px-4 py-2 btn btn-primary"
                      >
                        Finalizar agendamento
                      </button>
                    ) : (
                      <button className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600">
                        Enviar
                      </button>
                    )}
                  </div>
                </>
              )}
              {currentStep === 4 && (
                <div className="scheduling-page resume-section p-10">
                  <h2 className="text-size-h2 text-center text-color-black font-semibold padding-top-20 padding-bottom-20">
                    Resumo do agendamento
                  </h2>
                  <div className="resume-container">
                    <h5 className="text-size-20 text-color-black font-bold margin-bottom-20">
                      AGD 001574
                    </h5>

                    <div className="row schedule-data">
                      <div className="col-4">
                        <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
                          Motorista
                        </label>
                        <div className="mt-1 p-2 border schedule-data__info">
                          Henrique de Sá
                        </div>
                      </div>
                      <div className="col-2">
                        <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
                          CNH
                        </label>
                        <div className="mt-1 p-2 border schedule-data__info">
                          00123456789
                        </div>
                      </div>
                      <div className="col-3">
                        <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
                          Telefone
                        </label>
                        <div className="mt-1 p-2 border schedule-data__info margin-bottom-10">
                          (11) 91234-5678
                        </div>
                      </div>
                      <div className="col-3">
                        <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
                          E-mail
                        </label>
                        <div className="mt-1 p-2 border schedule-data__info">
                          h.sa@volkswagen.com.br
                        </div>
                      </div>
                    </div>

                    <div className="p-4 bg-lighter margin-top-20 vehicle-detais">
                      <div className="flex items-start gap-4">
                        <img
                          src="/assets/images/vehicle-thumb.png"
                          alt="Vehicle"
                          className="w-20 h-20 rounded"
                        />

                        <div className="flex-1 padding-left-10">
                          <p className="text-color-black text-size-16 font-medium m-0">
                            Meteor VW29.530 2024 | Placa: LSN4I49
                          </p>
                          <p className="text-color-status-resting text-size-14 m-0">
                            Chassi - 9536G8241LR021010
                            <br />
                            Odômetro: 5.032 km | Horímetro: 147:26:59
                          </p>
                        </div>

                        <div className="flex gap-6 margin-bottom-20">
                          <span className="px-2 py-1 green-tag">RIO</span>
                          <span className="px-2 py-1 green-tag">
                            Em Garantia
                          </span>
                          <span className="px-2 py-1 bg-white white-tag">
                            Volkstotal Prev
                          </span>
                        </div>
                      </div>

                      <div className="padding-top-10">
                        <div className="car-workshop">
                          <ul className="flex flex-column gap-5 m-0">
                            <li className="flex justify-between">
                              <div className="text-color-black">
                                Ação de oficina
                              </div>
                              <div className="text-color-black font-bold">
                                Grátis
                              </div>
                            </li>

                            <li className="flex justify-between">
                              <div className="text-color-black">
                                Revisão Assentamento (5.000 km ou 6 meses)
                              </div>
                              <div className="text-color-black font-bold">
                                Grátis
                              </div>
                            </li>

                            <li className="flex justify-between">
                              <div className="text-color-black">
                                Total em peças
                              </div>
                              <div className="text-color-black">R$4.628,00</div>
                            </li>

                            <li className="flex justify-between">
                              <div className="text-color-black">
                                Total em serviços
                              </div>
                              <div className="text-color-black">R$50,00</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="revision-itens">
                      <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
                        Itens cobertos na revisão
                      </h5>
                      <div className="p-4 bg-white border">
                        <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
                          <li className="flex justify-between">
                            Inspeção visual do veículo
                          </li>
                          <li className="flex justify-between">
                            Ajustes básicos (ex: regulagem de luzes e espelhos)
                          </li>
                          <li className="flex justify-between">
                            Verificação de torque de porcas de roda
                          </li>
                          <li className="flex justify-between">
                            Checagem de níveis de fluidos
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="revision-itens">
                      <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
                        Descrição dos serviços
                      </h5>
                      <div className="p-4 bg-white border">
                        <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
                          <li className="flex justify-between">
                            <span>Análise de fluido</span>
                            <span>Predic</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="revision-itens observation">
                      <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
                        Observações
                      </h5>
                      <div className="p-4 bg-white border">
                        <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
                          <li className="flex justify-between">
                            <span>
                              As peças serão retiradas na concessionária
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="revision-itens flex justify-between gap-6">
                      <div className="w-1/3">
                        <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
                          Concessionária
                        </h5>
                        <div className="p-4 bg-white border card">
                          <p className="m-0 text-size-h5">Maggi Oficina</p>
                          <p className="m-0">
                            Rodovia Br 116 - Km 439,5 - Arapongal, Registro -
                            SP, 04135-000
                          </p>
                        </div>
                      </div>

                      <div className="w-1/3">
                        <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
                          Data e horário:
                        </h5>
                        <div className="p-4 bg-white border card">
                          <p className="m-0 text-size-h5">
                            18 de outubro de 2024
                          </p>
                          <p className="m-0">12:00</p>
                        </div>
                      </div>

                      <div className="w-1/3">
                        <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
                          Consultor:
                        </h5>
                        <div className="p-4 bg-white border card">
                          <p className="m-0 text-size-h5">Lays Silva</p>
                        </div>
                      </div>
                    </div>

                    <div className="warning-row">
                      <h5 className="text-color-status-resting">Atenção!</h5>
                      <p className="text-color-warning text-size-18 font-semibold">
                        Os itens de cobertura do seu plano de contrato de
                        manutenção estão sujeitas a análise técnica de garantia,
                        podendo a garantia ser indeferida por violação e ou mau
                        uso.
                      </p>
                      <p className="margin-top-20">
                        <Checkbox indeterminate>
                          Estou ciente sobre as regras da garantia
                        </Checkbox>
                      </p>
                    </div>
                    <div className="flex justify-between navigation-buttons step-4">
                      <button className="back-btn" onClick={prevStep}>
                        Voltar
                      </button>
                      {currentStep < 4 ? (
                        <button
                          onClick={nextStep}
                          className="px-4 py-2 btn btn-primary"
                        >
                          Finalizar agendamento
                        </button>
                      ) : (
                        <button className="px-4 py-2 btn btn-primary">
                          Confirmar
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Page>
    </div>
  );
};
