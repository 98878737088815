"use client";
import { ReactNode } from "react";

import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import Header from "./Header";
import LeftSidebar from "./LeftSidebar";
import SubnavigationHeader from "./SubnavigationHeader";

interface IHeaderProps {
  children: ReactNode;
}

const Page = ({ children }: IHeaderProps) => {
  return (
    <ApplicationLayout className="MyServiceLayout bg-lighter height-500">
      <Header />
      <LeftSidebar />

      <SubnavigationHeader>{children}</SubnavigationHeader>
    </ApplicationLayout>
  );
};

export default Page;
