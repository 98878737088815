import { useEffect, useState } from "react";
import Page from "../Page";
import MonthCalendar from "./components/monthCalendar";
import NextSchedulings from "./components/nextSchedulings";
import WeekCalendar from "./components/weekCalendar";
import { getFirstDayOfWeek, getLastDayOfWeek } from "../../utils/dateUtils";
import { Scheduling } from "../../models/schedule";
import { fetchConsultantSchedule } from "../../services/api/fetchConsultantSchedule";
import BigNumbers from "./components/bigNumbers";

const MaintenanceControlContainer = () => {
	const [currentDate, ] = useState(new Date());
	const [scheduleSidebar, setScheduleSidebar] = useState(true);
	const [consultantMonthSchedule, setConsultantMonthSchedule] = useState<Scheduling[]>([]);

	useEffect(() => {
		const fetchSchedule = async () => {
			const consultantMonthSchedule = await fetchConsultantSchedule(
				currentDate.getMonth() + 1, 
				currentDate.getFullYear()
			);
			setConsultantMonthSchedule(consultantMonthSchedule);
		}
		
		fetchSchedule();
	}, []);
	
	return (
		<Page>
			<BigNumbers 
				consultantMonthSchedule={consultantMonthSchedule} 
				firstWeekDay={getFirstDayOfWeek(currentDate)} 
			/>

			<div className="text-size-18 text-bold padding-y-10">Agenda de controle das manuteções</div>
			<div className="panel panel-default panel-body shadow-default padding-0">
				<div className="display-flex gap-10">
					{scheduleSidebar && <div className="flex-basis-250">
						<MonthCalendar dayChoosed={currentDate} />
						<NextSchedulings now={currentDate} consultantMonthSchedule={consultantMonthSchedule} />
					</div>}

					<div style={{ borderLeftWidth: 1 }} className="width-100pct">
						<WeekCalendar 
							firstWeekDay={getFirstDayOfWeek(currentDate)}
							lastWeekDay={getLastDayOfWeek(currentDate)}
							consultantMonthSchedule={consultantMonthSchedule}
							currentDate={currentDate}
							handleScheduleSidebar={() => setScheduleSidebar(!scheduleSidebar)}
						/>
					</div>
				</div>
			</div>
		</Page>
	);
}

export default MaintenanceControlContainer;