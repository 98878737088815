import './styles.css';

interface ICalendarTitleProps {
    firstWeekDay: Date;
    lastWeekDay: Date;
    handleScheduleSidebar: () => void;
    handleCreateSchedulingDialog: () => void;
}

const Title = ({ firstWeekDay, lastWeekDay, handleScheduleSidebar, handleCreateSchedulingDialog }: ICalendarTitleProps) => {
    
    const getFormatedFirstWeekDay = () => {
        if (firstWeekDay.getMonth() !== lastWeekDay.getMonth()) {
            return firstWeekDay.toLocaleDateString('pt-BR', { day: '2-digit', month: 'long' }).replace(/ de /g, ' ');
        } else {
            return firstWeekDay.toLocaleDateString('pt-BR', { day: '2-digit' });
        }
    }

    const renderFirstWeekFullYear = () => {
        return (
            <span className="text-normal">
                {firstWeekDay.getFullYear() !== lastWeekDay.getFullYear() ? firstWeekDay.getFullYear() : ''}
            </span>
        );
    }

    return (
        <div 
            className="height-80 width-100pct display-grid gap-20 grid-cols-2-1fr-auto"
            style={{ borderBottomWidth: 1 }}
        >
            <div className="display-flex align-items-center">
                <span className="rioglyph rioglyph-th-list padding-15 text-size-20 cursor-pointer" onClick={handleScheduleSidebar} />
                <span className="padding-15 text-medium text-capitalize week-calendar-title">
                    {getFormatedFirstWeekDay()}{renderFirstWeekFullYear()}
                    -{lastWeekDay
                        .toLocaleDateString('pt-BR', { day: '2-digit', month: 'long' })
                        .replace(/ de /g, ' ')
                    } <span className="text-normal">{lastWeekDay.getFullYear()}</span>
                </span>
                <span className="btn btn-primary btn-outline">
                    <span className="text-capitalize padding-right-10">Semana</span>
                    <span className="rioglyph rioglyph-chevron-down" aria-hidden="true" />
                </span>
            </div>
            <div className="display-flex align-items-center padding-20">
                <button type="button" className="btn bg-highlight-darker padding-7" onClick={handleCreateSchedulingDialog}>
                    <span className="text-capitalize padding-right-5">Criar agendamento</span>
                    <span className="rioglyph rioglyph-plus-sign" aria-hidden="true"/>
                </button>
            </div>
        </div>
    )
}

export default Title;