import "./styles.scss";
import { SetStateAction, useState } from "react";

interface IIVehicleAlertsProps {
  closeSidebar: () => void;
}

const VehicleAlerts = ({ closeSidebar }: IIVehicleAlertsProps) => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [expandedView, setExpandedView] = useState(false);

  const handleTabClick = (tab: SetStateAction<string>) => {
    setActiveTab(tab);
    setExpandedView(false);
  };

  const handleExpandClick = () => {
    setExpandedView(true);
  };

  const handleBackClick = () => {
    setExpandedView(false);
  };

  return (
    <div className="right-sidebar">
      <h3 className="flex gap-6 margin-bottom-20">
        <span className="rioglyph rioglyph-warning-sign"></span>
        Alertas do veículo
      </h3>
      <div
        className="close-sidebar cursor-pointer"
        onClick={() => closeSidebar()}
      >
        <span className="rioglyph rioglyph-remove"></span>
      </div>

      <div className="w-full">
        {/* Abas */}
        {!expandedView && (
          <div className="flex justify-center alert-tabs">
            <button
              className={`p-4 w-1/4 ${
                activeTab === "tab1" ? "active-tab" : "text-gray-500"
              }`}
              onClick={() => handleTabClick("tab1")}
            >
              <i className="rioglyph rioglyph-component-custom-recurrent"></i>
            </button>
            <button
              className={`p-4 w-1/4 ${
                activeTab === "tab2" ? "active-tab" : "text-gray-500"
              }`}
              onClick={() => handleTabClick("tab2")}
            >
              <i className="rioglyph rioglyph-brake"></i>
              <span className="tab-alert-warning"></span>
            </button>
            <button
              className={`p-4 w-1/4 ${
                activeTab === "tab3" ? "active-tab" : "text-gray-500"
              }`}
              onClick={() => handleTabClick("tab3")}
            >
              <i className="rioglyph rioglyph-temperature"></i>
            </button>
            <button
              className={`p-4 w-1/4 ${
                activeTab === "tab4" ? "active-tab" : "text-gray-500"
              }`}
              onClick={() => handleTabClick("tab4")}
            >
              <i className="rioglyph rioglyph-battery-level-full"></i>
              <span className="tab-alert-warning"></span>
            </button>
          </div>
        )}

        {/* Conteúdo das Abas */}
        <div className="p-4">
          {!expandedView ? (
            <div
              onClick={handleExpandClick}
              className="alert-tabs__content cursor-pointer"
            >
              {activeTab === "tab1" && (
                <>
                  <h2 className="text-lg font-semibold mb-2">Manutenção</h2>
                  <div className="callout callout-secondary">
                    <div className="text-color-black text-medium text-size-14 line-height-125rel margin-bottom-0">
                      Alerta de Componente
                    </div>
                    <p className="margin-bottom-0 margin-top-0">
                      Sensor de Pressão do Óleo Lubrificante
                    </p>
                    <p className="margin-bottom-0 margin-top-0">
                      12/10/2024 14:33
                    </p>
                  </div>
                </>
              )}

              {activeTab === "tab2" && (
                <>
                  <h2 className="text-lg font-semibold mb-2">Freios</h2>
                  <div className="callout callout-secondary">
                    <div className="text-color-black text-medium text-size-14 line-height-125rel margin-bottom-0">
                      Alerta de freio
                    </div>
                    <p className="margin-bottom-0 margin-top-0">
                      Sensor 2 de pressão do freio acima do limite máximo
                    </p>
                    <p className="margin-bottom-0 margin-top-0">
                      09/10/2024 15:53
                    </p>
                  </div>
                </>
              )}

              {activeTab === "tab3" && (
                <>
                  <h2 className="text-lg font-semibold mb-2">Fluído</h2>
                  <div className="callout callout-secondary">
                    <div className="text-color-black text-medium text-size-14 line-height-125rel margin-bottom-0">
                      Alerta de fluído
                    </div>
                    <p className="margin-bottom-0 margin-top-0">
                      Sensor de Pressão do Óleo Lubrificante
                    </p>
                    <p className="margin-bottom-0 margin-top-0">
                      12/10/2024 14:33
                    </p>
                  </div>
                </>
              )}

              {activeTab === "tab4" && (
                <>
                  <h2 className="text-lg font-semibold mb-2">Bateria</h2>
                  <div className="callout callout-secondary">
                    <div className="text-color-black text-medium text-size-14 line-height-125rel margin-bottom-0">
                      Alerta de bateria
                    </div>
                    <p className="margin-bottom-0 margin-top-0">
                      Tensão da Bateria: Sinal Acima do Limite
                    </p>
                    <p className="margin-bottom-0 margin-top-0">
                      08/10/2024 10:15
                    </p>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="expanded-view">
              {activeTab === "tab1" && (
                <div>
                  <div className="flex items-center gap-4 expanded-view__header">
                    <button
                      onClick={handleBackClick}
                      className="m-0 back-button"
                    >
                      <span className="rioglyph rioglyph-arrow-left"></span>
                    </button>
                    <p className="text-lg font-semibold m-0">Manutenção</p>
                  </div>
                  <div className="flex flex-col alert-detail">
                    <div className="flex alert-item">
                      <span>
                        3087/05 - Sensor de Pressão do Óleo Lubrificante
                      </span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>Data e hora:</span>
                      <span>09/10/2024 15:53</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>Descrição:</span>
                      <span>Sensor de Pressão do Óleo Lubrificante</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>SPN:</span>
                      <span>003087</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>FMI:</span>
                      <span>05 - baixa nível de fluído</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>Status do alerta:</span>
                      <span>Alerta crítico</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>Odômetro:</span>
                      <span>81.580 KM</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>SA:</span>
                      <span>ECM - Módulo do motor</span>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === "tab2" && (
                <div>
                  <div className="flex items-center gap-4 expanded-view__header">
                    <button
                      onClick={handleBackClick}
                      className="m-0 back-button"
                    >
                      <span className="rioglyph rioglyph-arrow-left"></span>
                    </button>
                    <p className="text-lg font-semibold m-0">Alerta de freio</p>
                  </div>
                  <div className="flex flex-col alert-detail">
                    <div className="flex alert-item">
                      <span>Sensor 2 de pressão de freio</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>Data e hora:</span>
                      <span>09/10/2024 15:53</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>Descrição:</span>
                      <span>
                        Sensor 2 de pressão do freio acima do limite máximo
                      </span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>SPN:</span>
                      <span>001851</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>FMI:</span>
                      <span>Acima do limite máximo</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>Status do alerta:</span>
                      <span>Alerta crítico</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>Odômetro:</span>
                      <span>155.540 KM</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>SA:</span>
                      <span>ABS - MÓDULO DO SISTEMA DE FREIO</span>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === "tab3" && (
                <div>
                  <div className="flex items-center gap-4 expanded-view__header">
                    <button
                      onClick={handleBackClick}
                      className="m-0 back-button"
                    >
                      <span className="rioglyph rioglyph-arrow-left"></span>
                    </button>
                    <p className="text-lg font-semibold m-0">
                      Alerta de fluído
                    </p>
                  </div>
                  <div className="flex flex-col alert-detail">
                    <div className="flex alert-item">
                      <span>
                        3087/05 - Sensor de Pressão do Óleo Lubrificante
                      </span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>Data e hora:</span>
                      <span>09/10/2024 15:53</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>Descrição:</span>
                      <span>Sensor de Pressão do Óleo Lubrificante</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>SPN:</span>
                      <span>003087</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>FMI:</span>
                      <span>05 - baixa nível de fluído</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>Status do alerta:</span>
                      <span>Alerta crítico</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>Odômetro:</span>
                      <span>81.580 KM</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>SA:</span>
                      <span>ECM - Módulo do motor</span>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === "tab4" && (
                <div>
                  <div className="flex items-center gap-4 expanded-view__header">
                    <button
                      onClick={handleBackClick}
                      className="m-0 back-button"
                    >
                      <span className="rioglyph rioglyph-arrow-left"></span>
                    </button>
                    <p className="text-lg font-semibold m-0">
                      Alerta de bateria
                    </p>
                  </div>
                  <div className="flex flex-col alert-detail">
                    <div className="flex alert-item">
                      <span>
                        0168/00 - Tensão da Bateria: Sinal Acima do Limite
                      </span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>Data e hora:</span>
                      <span>09/10/2024 15:53</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>Descrição:</span>
                      <span>Tensão da Bateria: Sinal Acima do Limite</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>SPN:</span>
                      <span>000168</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>FMI:</span>
                      <span>00</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>Status do alerta:</span>
                      <span>Alerta crítico</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>Odômetro:</span>
                      <span>224.120 KM</span>
                    </div>
                    <div className="flex justify-between alert-item">
                      <span>SA:</span>
                      <span>BSG: Módulo da cabine</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VehicleAlerts;
