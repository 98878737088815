import { useEffect, useState } from "react";

import inspectionsService, {
  IInspection,
} from "../../services/inspections.service";

import "./styles.scss";
import InspectionList from "./inspection-list";
import Inspection from "./inspection";

interface IInspectionProps {
  closeSidebar: () => void;
}

export default ({ closeSidebar }: IInspectionProps) => {
  const [inspections, setInspections] = useState<IInspection[]>([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredInspections, setFilteredInspections] = useState<IInspection[]>(
    []
  );

  const [inspection, setInspection] = useState<IInspection | null>(null);

  useEffect(() => {
    const fetchInspections = async () => {
      try {
        const inspections = await inspectionsService.getUserInspections("1");
        setInspections(inspections);
        setFilteredInspections(inspections);
      } catch (error) {
        console.error("Failed to fetch inspections", error);
      }
    };

    fetchInspections();
  }, []);

  useEffect(() => {
    setFilteredInspections(
      inspections.filter((inspection) =>
        inspection.model.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, inspections]);

  const markInspectionAsViewed = async (inspectionId: string) => {
    setInspections(
      inspections.map((inspection) =>
        inspection.id === inspectionId
          ? { ...inspection, viewed: true }
          : inspection
      )
    );
  };

  const markAllInspectionsAsViewed = async () => {
    setInspections(
      inspections.map((inspection) => ({ ...inspection, viewed: true }))
    );
  };

  return (
    <div className="right-sidebar">
      <h3 className="flex gap-6 margin-bottom-20">
        <span className="rioglyph rioglyph-calendar-today"></span>
        Campanhas de Campo
      </h3>
      <div
        className="close-sidebar cursor-pointer"
        onClick={() => closeSidebar()}
      >
        <span className="rioglyph rioglyph-remove"></span>
      </div>
      {!inspection && (
        <InspectionList
          inspections={filteredInspections}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          markInspectionAsViewed={markInspectionAsViewed}
          markAllInspectionsAsViewed={markAllInspectionsAsViewed}
          chooseInspection={setInspection}
        />
      )}

      {inspection && (
        <Inspection
          inspection={inspection}
          setInspection={() => setInspection(null)}
        />
      )}
    </div>
  );
};
