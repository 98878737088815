import { SchedulingType } from "../../enums/taskType";
import { Scheduling } from "../../models/schedule";
import { assetDto } from "./assetDto";
import { dealershipDto } from "./dealershipDto";
import { driverDto } from "./driverDto";

export const SchedulingDto = (data: any): Scheduling => {
    return {
        type: SchedulingType.PREVENTIVA, //data.checkup.checkupType.name,
        description: `${data.checkup.desc ?? ''}  (${data.checkup?.range?.end} km)`,
        asset: assetDto(data.vehicleSchedule.vehicle),
        driver: driverDto(data.vehicleSchedule.driver),
        itens: data.checkup.checkupType.services
            .map((service:any) => service.items).flat(),
        observation: data.vehicleSchedule.generalObservations,
        consultant: `${data.consultant?.firstName ?? ''} ${data.consultant?.lastName ?? ''}`,
        dealership: dealershipDto(data.vehicleSchedule.dealerShip),
        scheduleTime: new Date(data.schedule.scheduledDate),
        services: data.checkup.checkupType.services
            .map((service:any) => service.name),
        totalPrice: data.vehicleSchedule.otherServices.total,
    };
}