import BigNumbers from "../components/bigNumbers";
import TableCommonDemo from "../components/maintenanceTable";
import Page from "../templates/Page";

export const PreventiveMaintenance = () => {
  return (
    <Page>
      <div>
        <BigNumbers />
        <div style={{ marginTop: 50 }}>
          <TableCommonDemo />
        </div>
      </div>
    </Page>
  );
};
