import { Navigate, Route, Routes } from 'react-router-dom';

import MaintenanceControlContainer from './pages/maintenanceControl/MaintenanceControlContainer';
import MaintenanceMonitoringContainer from './pages/maintenanceMonitoring/MaintenanceMonitoringContainer';
import ScheduleContainer from './pages/schedule/ScheduleContainer';

const DealershipRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/dealership/maintenance-control" />} />
    <Route path="/maintenance-control" element={<MaintenanceControlContainer />} />
    <Route path="/maintenance-monitoring" element={<MaintenanceMonitoringContainer />} />
    <Route path="/schedule" element={<ScheduleContainer />} />
  </Routes>
);

export default DealershipRoutes;
