import { ReactNode } from "react";
import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import ApplicationLayoutBodyNavigation from "@rio-cloud/rio-uikit/ApplicationLayoutBodyNavigation";
import SubNavigation from "@rio-cloud/rio-uikit/SubNavigation";
import { NavLink } from "react-router-dom";
import Header from "../../../templates/Header";
import LeftSidebar from "../../../templates/LeftSidebar";

interface ISubnavitationHeaderProps { children: ReactNode }
const SubNavigationHeader = ({ children }: ISubnavitationHeaderProps) => {
  const navItems = [
    { key: "dealership/maintenance-control", value: "Controle de manutenções" },
    { key: "dealership/maintenance-monitoring", value: "Acompanhamento da manutenção" },
    { key: "dealership/schedule", value: "Meus agendamentos" },
  ];

  return (
    <ApplicationLayout.Body
      className="uikitDemo"
      navigation={
        <ApplicationLayoutBodyNavigation>
          <SubNavigation
            navItems={navItems
              .map((item) => ({
                key: item.key,
                route: <NavLink to={`/${item.key}`}>{item.value}</NavLink>,
              }))
            }
          />
        </ApplicationLayoutBodyNavigation>
      }
    >
      {children}
    </ApplicationLayout.Body>
  );
};

interface IHeaderProps { children: ReactNode }
const Page = ({ children }: IHeaderProps) => {
  return (
    <ApplicationLayout className="MyServiceLayout bg-lighter height-500">
      <Header />
      <LeftSidebar />
      <SubNavigationHeader>{children}</SubNavigationHeader>
    </ApplicationLayout>
  );
};

export default Page;