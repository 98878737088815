import { Asset } from "../../models/asset";

export const assetDto = (data: any): Asset => {
    return {
        id: data.assetId,
        name: data.vehicleModel,
        imageUrl: data.imageUrl,
        group: data.vehicleGroup,
        licensePlate: data.plate,
        chassis: data.chassis,
        odometer: data.odometer,
        hourmeter: data.hourMeter,
        tags: data.tags
    };
}