import { useState } from "react";
import Checkbox from "@rio-cloud/rio-uikit/Checkbox";
import "./styles.scss";

const ScheduleDetails = () => {
  const [, setShowDialog] = useState(false);

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  return (
    <div className="foo">
      <div className="">
        <h5 className="text-size-20 text-color-black font-bold margin-bottom-20">
          AGD 001574
        </h5>

        <div className="row schedule-data">
          <div className="col-4">
            <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
              Motorista
            </label>
            <div className="mt-1 p-2 border schedule-data__info">
              Henrique de Sá
            </div>
          </div>
          <div className="col-2">
            <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
              CNH
            </label>
            <div className="mt-1 p-2 border schedule-data__info">
              00123456789
            </div>
          </div>
          <div className="col-3">
            <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
              Telefone
            </label>
            <div className="mt-1 p-2 border schedule-data__info margin-bottom-10">
              (11) 91234-5678
            </div>
          </div>
          <div className="col-3">
            <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
              E-mail
            </label>
            <div className="mt-1 p-2 border schedule-data__info">
              h.sa@volkswagen.com.br
            </div>
          </div>
        </div>

        <div className="p-4 bg-lighter margin-top-20 vehicle-detais">
          <div className="flex items-start gap-4">
            <img
              src="/assets/images/vehicle-thumb.png"
              alt="Vehicle"
              className="w-20 h-20 rounded"
            />

            <div className="flex-1 padding-left-10">
              <p className="text-color-black text-size-16 font-medium m-0">
                Meteor VW29.530 2024 | Placa: LSN4I49
              </p>
              <p className="text-color-status-resting text-size-14 m-0">
                Chassi - 9536G8241LR021010
                <br />
                Odômetro: 5.032 km | Horímetro: 147:26:59
              </p>
            </div>

            <div className="flex gap-6 margin-bottom-20">
              <span className="px-2 py-1 green-tag">RIO</span>
              <span className="px-2 py-1 green-tag">Em Garantia</span>
              <span className="px-2 py-1 bg-white white-tag">
                Volkstotal Prev
              </span>
            </div>
          </div>

          <div className="padding-top-10">
            <div className="car-workshop">
              <ul className="flex flex-column gap-5 m-0">
                <li className="flex justify-between">
                  <div className="text-color-black">Ação de oficina</div>
                  <div className="text-color-black font-bold">Grátis</div>
                </li>

                <li className="flex justify-between">
                  <div className="text-color-black">
                    Revisão Assentamento (5.000 km ou 6 meses)
                  </div>
                  <div className="text-color-black font-bold">Grátis</div>
                </li>

                <li className="flex justify-between">
                  <div className="text-color-black">Total em peças</div>
                  <div className="text-color-black">R$4.628,00</div>
                </li>

                <li className="flex justify-between">
                  <div className="text-color-black">Total em serviços</div>
                  <div className="text-color-black">R$50,00</div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="revision-itens">
          <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
            Itens cobertos na revisão
          </h5>
          <div className="p-4 bg-white border">
            <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
              <li className="flex justify-between">
                Inspeção visual do veículo
              </li>
              <li className="flex justify-between">
                Ajustes básicos (ex: regulagem de luzes e espelhos)
              </li>
              <li className="flex justify-between">
                Verificação de torque de porcas de roda
              </li>
              <li className="flex justify-between">
                Checagem de níveis de fluidos
              </li>
            </ul>
          </div>
        </div>

        <div className="revision-itens">
          <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
            Descrição dos serviços
          </h5>
          <div className="p-4 bg-white border">
            <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
              <li className="flex justify-between">
                <span>Análise de fluido</span>
                <span>Predic</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="revision-itens observation">
          <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
            Observações
          </h5>
          <div className="p-4 bg-white border">
            <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
              <li className="flex justify-between">
                <span>As peças serão retiradas na concessionária</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="revision-itens flex justify-between gap-6">
          <div className="w-1/3">
            <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
              Concessionária
            </h5>
            <div className="p-4 bg-white border card">
              <p className="m-0 text-size-h5">Maggi Oficina</p>
              <p className="m-0">
                Rodovia Br 116 - Km 439,5 - Arapongal, Registro - SP, 04135-000
              </p>
            </div>
          </div>

          <div className="w-1/3">
            <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
              Data e horário:
            </h5>
            <div className="p-4 bg-white border card">
              <p className="m-0 text-size-h5">18 de outubro de 2024</p>
              <p className="m-0">12:00</p>
            </div>
          </div>

          <div className="w-1/3">
            <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
              Consultor:
            </h5>
            <div className="p-4 bg-white border card">
              <p className="m-0 text-size-h5">Lays Silva</p>
            </div>
          </div>
        </div>

        <div className="warning-row">
          <h5 className="text-color-status-resting">Atenção!</h5>
          <p className="text-color-warning text-size-18 font-semibold">
            Os itens de cobertura do seu plano de contrato de manutenção estão
            sujeitas a análise técnica de garantia, podendo a garantia ser
            indeferida por violação e ou mau uso.
          </p>
          <p>
            <Checkbox indeterminate>
              Estou ciente sobre as regras da garantia
            </Checkbox>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ScheduleDetails;
