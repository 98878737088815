export interface IInspection {
  id: string;
  action: string;
  fullAction: string;
  model: string;
  models: string;
  photoUrl: string;
  description: string;
  viewed?: boolean;
}

export const mockInspections: IInspection[] = [
  {
    id: '1',
    action: 'Substituição de cinto de segurança',
    fullAction:
      'Substituição do parafuso de fixação da bolsa pneumática traseira da cabine - DVM AT 021-24',
    model: 'Meteor',
    models: 'Meteor 28.460, 28.480, 29.520 e 29.530 | Constellation 25.480',
    photoUrl:
      'https://assets.disaltecnologia.com.br/catalog/cover/e1c1013faa90d70ee3142d3fe94d9557.webp',
    description:
      'A Volkswagen Caminhões e Ônibus está iniciando uma Ação de Campo para substituição do parafuso superior de fixação da bolsa pneumática traseira, lado direito, da suspensão da cabine. Um lote deste parafuso foi produzido sem tratamento térmico e esta Ação de Campo consiste em substituir o referido parafuso em 196 veículos afetados.',
    viewed: true,
  },
  {
    id: '2',
    action: 'Substituição de cinto de segurança',
    fullAction:
      'Substituição do parafuso de fixação da bolsa pneumática traseira da cabine - DVM AT 021-24',
    model: 'Constelation',
    models: 'Meteor 28.460, 28.480, 29.520 e 29.530 | Constellation 25.480',
    photoUrl:
      'https://assets.disaltecnologia.com.br/catalog/cover/e1c1013faa90d70ee3142d3fe94d9557.webp',
    description:
      'A Volkswagen Caminhões e Ônibus está iniciando uma Ação de Campo para substituição do parafuso superior de fixação da bolsa pneumática traseira, lado direito, da suspensão da cabine. Um lote deste parafuso foi produzido sem tratamento térmico e esta Ação de Campo consiste em substituir o referido parafuso em 196 veículos afetados.',
    viewed: false,
  },
  {
    id: '3',
    action: 'Substituição de cinto de segurança',
    fullAction:
      'Substituição do parafuso de fixação da bolsa pneumática traseira da cabine - DVM AT 021-24',
    model: 'Meteor',
    models: 'Meteor 28.460, 28.480, 29.520 e 29.530 | Constellation 25.480',
    photoUrl:
      'https://assets.disaltecnologia.com.br/catalog/cover/e1c1013faa90d70ee3142d3fe94d9557.webp',
    description:
      'A Volkswagen Caminhões e Ônibus está iniciando uma Ação de Campo para substituição do parafuso superior de fixação da bolsa pneumática traseira, lado direito, da suspensão da cabine. Um lote deste parafuso foi produzido sem tratamento térmico e esta Ação de Campo consiste em substituir o referido parafuso em 196 veículos afetados.',
    viewed: false,
  },
];

const InspectionsService = {
  async getUserInspections(userId: string) {
    try {
      return mockInspections;
    } catch (error) {
      console.error('Error fetching user inspections:', error);
      throw error;
    }
  },
};

export default InspectionsService;
