import { useEffect, useState } from "react";
import { addDays, getStringWeekDaysStartingFromDate } from "../../../../utils/dateUtils";
import SchedulingCard from "./SchedulingCard";
import { DaySchedule, Scheduling, WeekSchedule } from "../../../../models/schedule";
import { getMaintenanceWeekSchedule } from "../../../../services/consultantScheduleService";
import { fetchConsultantSchedule } from "../../../../services/api/fetchConsultantSchedule";

interface IScheduleProps {
    firstWeekDay: Date;
    currentDate: Date;
    consultantMonthSchedule: Scheduling[];
    handleCreateSchedulingDialog: () => void;
    handleScheduling: (scheduling: Scheduling) => void;
}

const Schedule = ({ firstWeekDay, currentDate, consultantMonthSchedule, handleCreateSchedulingDialog, handleScheduling }: IScheduleProps) => {
    const [weekSchedule, setWeekSchedule] = useState<WeekSchedule | undefined>(undefined);

    useEffect(() => {
        const fetchWeekSchedule = async () => {
            const weekSchedule = await getMaintenanceWeekSchedule(firstWeekDay, consultantMonthSchedule);
            setWeekSchedule(weekSchedule);
        }
        fetchWeekSchedule();
    }, [firstWeekDay]);

    const getDaySchedule = (date: Date): DaySchedule | undefined => weekSchedule?.days.find((daySchedule) => {
        const day = new Date(daySchedule.date);
        return day.getFullYear() === date.getFullYear() &&
            day.getMonth() === date.getMonth() &&
            day.getDate() === date.getDate();
    });

    const getScheduleTask = (date: Date, hour: number): JSX.Element | undefined => {
        const daySchedule = getDaySchedule(date);
        if (!daySchedule) return undefined;

        const task = daySchedule.schedulings.find((task) => {
            const taskDate = new Date(task.scheduleTime);
            return taskDate.getHours() === hour;
        });
        return task ? 
            <SchedulingCard task={task} handleScheduling={handleScheduling} /> : 
            <div className="cursor-pointer width-100pct height-100pct" onClick={handleCreateSchedulingDialog} />;
    }

    return (
        <table className="width-100pct">
            <thead>
                <tr>
                    <th className="width-60 height-20"></th>
                    {
                        getStringWeekDaysStartingFromDate(firstWeekDay)
                            .map((day, index) => (
                                <th 
                                    className={`
                                        height-20 text-size-12 text-medium
                                        ${currentDate.getDate() === firstWeekDay.getDate() + index ? 'bg-highlight-lighter border' : ''}
                                    `} 
                                    key={index}
                                >{day}</th>
                            ))
                    }
                </tr>
            </thead>
            <tbody>
                {
                    Array.from({ length: 12 }).map((_, hour) => (
                        <tr key={hour + 7}>
                            <td className="display-flex justify-content-center align-items-center">{`${hour + 7}:00`}</td>
                            {Array.from({ length: 7 }).map((_, day) => (
                                <td
                                    style={{ height: 121, width: 100 }}
                                    className={`border ${currentDate.getDate() === firstWeekDay.getDate() + day ? 'bg-highlight-decent' : ''}`}
                                    key={day}
                                >{getScheduleTask(addDays(firstWeekDay, day), hour + 7)}</td>
                            ))}
                        </tr>
                    ))
                }
            </tbody>
        </table>
    );
}

export default Schedule;