import Calendar from "./Calendar";

export interface IMonthCalendarProps {
	dayChoosed: Date;
}

const MonthCalendar = (props: IMonthCalendarProps) => {
	return <Calendar {...props} />
};

export default MonthCalendar;