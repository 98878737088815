"use client";
import StatsWidgets from "@rio-cloud/rio-uikit/StatsWidgets";
import StatsWidget from "@rio-cloud/rio-uikit/StatsWidget";
import StatsWidgetNumber from "@rio-cloud/rio-uikit/StatsWidgetNumber";
import StatsWidgetSpacer from "@rio-cloud/rio-uikit/StatsWidgetSpacer";
import "./styles.scss";

const BigNumbers = () => (
  <>
    <StatsWidgets className="big-numbers">
      <StatsWidget className="card">
        <StatsWidget.Header>Manutenções</StatsWidget.Header>
        <StatsWidget.Body>
          <StatsWidgetNumber
            className="text-color-status-driving"
            value="03"
            label="Agendar"
          />
          <StatsWidgetSpacer />
          <StatsWidgetNumber
            className="text-color-danger"
            value="02"
            label="Vencidas"
          />
        </StatsWidget.Body>
        <StatsWidget.Body>
          <StatsWidgetNumber value="01" label="Agendadas" />
          <StatsWidgetSpacer />
          <StatsWidgetNumber value="01" label="Em Manutenção" />
        </StatsWidget.Body>
      </StatsWidget>

      <StatsWidget className="card">
        <StatsWidget.Header>RIO</StatsWidget.Header>
        <StatsWidget.Body>
          <StatsWidgetNumber
            className="text-color-status-driving"
            value="05"
            label="Com RIO"
          />
          <StatsWidgetSpacer />
          <StatsWidgetNumber
            className="text-color-danger"
            value="02"
            label="Sem RIO"
          />
        </StatsWidget.Body>
        <StatsWidget.Body>
          <StatsWidgetNumber value="03" label="Premium" />
          <StatsWidgetSpacer />
          <StatsWidgetNumber value="01" label="Basics" />
          <StatsWidgetSpacer />
          <StatsWidgetNumber value="01" label="E-fleet" />
        </StatsWidget.Body>
      </StatsWidget>

      <StatsWidget className="card">
        <StatsWidget.Header>VolksTotal</StatsWidget.Header>
        <StatsWidget.Body>
          <StatsWidgetNumber
            className="text-color-status-driving"
            value="04"
            label="Com VolksTotal"
          />
          <StatsWidgetSpacer />
          <StatsWidgetNumber
            className="text-color-danger"
            value="05"
            label="Sem VolksTotal"
          />
        </StatsWidget.Body>
        <StatsWidget.Body>
          <StatsWidgetNumber value="02" label="Prev" />
          <StatsWidgetSpacer />
          <StatsWidgetNumber value="01" label="Max" />
          <StatsWidgetSpacer />
          <StatsWidgetNumber value="01" label="Plus" />
        </StatsWidget.Body>
      </StatsWidget>
    </StatsWidgets>
  </>
);

export default BigNumbers;
