import { BrowserRouter } from "react-router-dom";
import { Routers } from "./routes";
import MaintenanceView from "./common/maintenanceView";

export default function App() {

  const maintenance = !!!localStorage.getItem('token');

  return maintenance ?
    <MaintenanceView /> :
    <BrowserRouter>
      <Routers />
    </BrowserRouter>
}
