import CustomState from "@rio-cloud/rio-uikit/CustomState";

const imageUrl = 'http://cdn.rio.cloud/svg/dashboard/dashboard_coming_soon.svg';

const MaintenanceView = () => (
    <div className="height-100vh">
        <div className="display-flex justify-content-center align-items-center height-100pct">
            <CustomState
            headline="Voltaremos em breve!"
            message="Estamos em manutenção programada e esperamos estar de volta muito em breve. Agradecemos pela paciência!"
            fullWidth
            image={<img className='max-width-500' src={imageUrl} alt={''} />}
            className='maintenanceMode'
            />
        </div>
    </div>
);

export default MaintenanceView;
