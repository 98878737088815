import SimpleDialog from '@rio-cloud/rio-uikit/SimpleDialog';

interface ICreateSchedulingDialogProps {
    show: boolean;
    onClose: () => void;
}

const CreateSchedulingDialog = ({show, onClose}: ICreateSchedulingDialogProps) => {

    const dialogContent = (
        <div>
            <span className='padding-y-15 text-color-darker text-size-14'>Qual será o tipo de revisão para esse agendamento?</span>

            <div className='padding-y-15'>
                <div className='display-grid grid-cols-2-1fr-max-content padding-y-15 text-size-16 border-bottom-only border-width-1'>
                    <span>Revisão Corretiva</span>
                    <div className='display-flex align-items-center cursor-pointer'>
                        <img src="/assets/images/right-arrow.svg" alt="Seta" />
                    </div>
                </div>
                <div className='display-grid grid-cols-2-1fr-max-content padding-y-15 text-size-16'>
                    <span>Revisão Corretiva</span>
                    <div className='display-flex align-items-center cursor-pointer'>
                        <img src="/assets/images/right-arrow.svg" alt="Seta" />
                    </div>
                </div>
            </div>
        </div>
    );

    const dialogFooter = (
        <div onClick={onClose} className='text-size-14 text-bold text-color-highlight-dark cursor-pointer'>
            Voltar
        </div>
    );

    return <SimpleDialog
        show={show}
        title='Tipo de revisão'
        content={dialogContent}
        footer={dialogFooter}
        bsSize='sm'
        onClose={onClose}
    />;
}

export default CreateSchedulingDialog;