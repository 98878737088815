import { Link, NavLink } from "react-router-dom";
import IframeResizer from "iframe-resizer-react";

import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import ApplicationHeader from "@rio-cloud/rio-uikit/ApplicationHeader";
import ActionBarItem from "@rio-cloud/rio-uikit/ActionBarItem";
import Notification from "@rio-cloud/rio-uikit/Notification";

const currentLocation = window.location.hash.replace("#", "/");
const navItems = [
  {
    key: "1",
    route: <NavLink to="/1">DASHBOARD</NavLink>,
  },
  {
    key: "2",
    route: (
      <NavLink to="/2" onClick={(event) => event.preventDefault()}>
        RELATÓRIO DE ALERTAS
      </NavLink>
    ),
  },
  {
    key: "3",
    route: (
      <NavLink to="/3" onClick={(event) => event.preventDefault()}>
        NOTIFICAÇÕES
      </NavLink>
    ),
  },
  {
    key: "4",
    route: (
      <NavLink to="/4" onClick={(event) => event.preventDefault()}>
        RECORRÊNCIA
      </NavLink>
    ),
  },
  {
    key: "5",
    route: (
      <NavLink to={currentLocation} onClick={(event) => event.preventDefault()}>
        MANUTENÇÃO
      </NavLink>
    ),
  },
];

const ServiceInfo = () => (
  <ActionBarItem.List>
    <ActionBarItem.ListItem
      icon="rioglyph-hand-right"
      onClick={() => Notification.info("Show Welcome Dialog")}
    >
      <div className="display-flex align-items-center gap-5">
        <span>Welcome</span>
        <span className="badge badge-primary">1</span>
      </div>
    </ActionBarItem.ListItem>
    <ActionBarItem.ListItem
      icon="rioglyph-exclamation-sign"
      onClick={() => Notification.info("Show What's New Dialog")}
    >
      Whats new?
    </ActionBarItem.ListItem>
    <ActionBarItem.ListItem
      icon="rioglyph-question-sign"
      onClick={() => Notification.info("Show Features Dialog")}
    >
      Features
    </ActionBarItem.ListItem>
    <ActionBarItem.ListItem icon="rioglyph-envelope" hasLink>
      <Link to="/components/appHeader">Feedback</Link>
    </ActionBarItem.ListItem>
  </ActionBarItem.List>
);

const ServiceInfoComponent = (
  <ActionBarItem id="serviceInfo" className="myItem">
    <ActionBarItem.Icon>
      <span className="icon rioglyph rioglyph-info-sign" />
    </ActionBarItem.Icon>
    <ActionBarItem.Popover
      className="myItemPopover"
      title={
        <div>
          <span>Service XYZ</span>
          <span className="text-color-gray margin-left-10">v1.1.0</span>
        </div>
      }
    >
      <ServiceInfo />
    </ActionBarItem.Popover>
  </ActionBarItem>
);

const Notifications = (
  <ActionBarItem id="notifications">
    <ActionBarItem.Icon>
      <span className="icon rioglyph rioglyph-notification" />
      <span className="badge badge-primary badge-indicator badge-indicator-pinging">
        4
      </span>
    </ActionBarItem.Icon>
  </ActionBarItem>
);

const AccountMenuComponent = (
  <ActionBarItem id="accountMenu" hidePopoverOnClick={false}>
    <ActionBarItem.Icon>
      <span className="icon rioglyph rioglyph-user" />
    </ActionBarItem.Icon>
    <ActionBarItem.Popover>
      <ActionBarItem.List>
        <ActionBarItem.ListItem>
          <div>
            <div className="text-medium">This is just an example</div>
            <div>The real account menu comes here...</div>
          </div>
        </ActionBarItem.ListItem>
        <ActionBarItem.ListSeparator />
        <ActionBarItem.ListItem className="padding-0">
          <iframe
            className="buyButton height-30 padding-top-5 padding-bottom-5"
            title="ContactForm"
            src="https://contact-form.rio.cloud/contact_link.html?opener=https%3A%2F%2Fhome.rio.cloud%2F&amp;locale=en-GB"
          />
        </ActionBarItem.ListItem>
        <ActionBarItem.ListSeparator />
        <ActionBarItem.ListItem className="padding-0">
          <button
            type="button"
            className="btn btn-link btn-link-inline text-color-dark"
            onClick={() => Notification.info("Logout")}
          >
            <span className="rioglyph rioglyph-off margin-right-5 text-size-16 text-color-dark" />
            <span>Logout</span>
          </button>
        </ActionBarItem.ListItem>
      </ActionBarItem.List>
    </ActionBarItem.Popover>
  </ActionBarItem>
);

export default () => {
  return (
    <ApplicationLayout.Header>
      <ApplicationHeader
        label="Monitor de Manutenção"
        // appNavigator={
        //   <IframeResizer
        //     className="iFrameResizer"
        //     src="https://menu.rio.cloud"
        //     checkOrigin={false}
        //   />
        // }
        navItems={navItems}
        actionBarItems={[
          ServiceInfoComponent,
          Notifications,
          AccountMenuComponent,
        ]}
      />
    </ApplicationLayout.Header>
  );
};
