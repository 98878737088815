import { Routes, Route } from 'react-router-dom';
import { PreventiveMaintenance, MyAppointments } from '../pages'

import DealershipRoutes from '../pages/dealership/routes'

export const Routers = () => {
    return (
        <Routes>
            <Route path="/" index element={<PreventiveMaintenance />} />
            <Route path="/meus-agendamentos" element={<MyAppointments />} />

            <Route path='/dealership/*' element={<DealershipRoutes />} />
        </Routes>
    )
}