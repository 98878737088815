import Page from "../Page";

const ScheduleContainer = () => {
	return (
		<Page>
			<div>
				<h1>Schedule</h1>
				<p>Manage your appointments.</p>
			</div>
		</Page>
	)
}

export default ScheduleContainer;