import { Scheduling } from "../../models/schedule";
import ApiService from "../../services/api/api";
import { SchedulingDto } from "../dtos/schedulingDto";

export const fetchConsultantSchedule = async (month: number, year: number): Promise<Scheduling[]> => {
    const testConsultant = '0aec1e8a-9015-49ac-ace1-d32b08c169b0';
    
    const apiService = new ApiService();
    const query = `month=${month}&year=${year}&consultant=${testConsultant}`
    try {
        const response = await apiService.get<any[]>(`/schedules/checkups/consultant?${query}`);
        if (response.status === 200) {
            return response.data.map(SchedulingDto);
        } else return [];
    } catch (error) {
        console.error(error);
        return [];
    }
}