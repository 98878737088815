import "./styles.scss";
import { FC, useState } from "react";
import Select from "@rio-cloud/rio-uikit/Select";

type RevisaoCardProps = {
  title: string;
  km: string;
  horas: string;
  os: string;
  data: string;
  horimetro: string;
  kmRodado: string;
  status: string;
};

interface IIPeriodicReviewsProps {
  closeSidebar: () => void;
}

const PeriodicReviews = ({ closeSidebar }: IIPeriodicReviewsProps) => {
  const [activeTab, setActiveTab] = useState<"revisoes" | "trocaDeOleo">(
    "revisoes"
  );

  const options5 = [
    { id: "1", label: "Por ordem" },
    { id: "2", label: "Por ordem" },
    { id: "3", label: "Por ordem", selected: true },
  ];

  return (
    <div className="right-sidebar">
      <h3 className="flex gap-6 margin-bottom-20">
        <span className="rioglyph rioglyph-book"></span>
        Revisões Periodicas
      </h3>
      <div
        className="close-sidebar cursor-pointer"
        onClick={() => closeSidebar()}
      >
        <span className="rioglyph rioglyph-remove"></span>
      </div>

      <div className="w-full">
        <div className="p-0">
          <div className="flex space-x-8 mb-6">
            <button
              onClick={() => setActiveTab("revisoes")}
              className={`text-size-16 font-semibold ${
                activeTab === "revisoes"
                  ? "border-b-2 border-black"
                  : "text-gray-400"
              }`}
            >
              Revisões
            </button>
            <button
              onClick={() => setActiveTab("trocaDeOleo")}
              className={`text-size-16 font-semibold ${
                activeTab === "trocaDeOleo"
                  ? "border-b-2 border-black"
                  : "text-gray-400"
              }`}
            >
              Troca de óleo
            </button>
          </div>

          {activeTab === "revisoes" && (
            <div className="revision-content">
              <div className="flex justify-between items-center actions-row">
                <button type="button" className="btn btn-default btn-icon-only">
                  <span className="rioglyph rioglyph-tachograph-download"></span>
                </button>
                <div className="form-group">
                  <div className="display-flex gap-10 align-items-end">
                    <div className="form-group">
                      <Select
                        className="select-form"
                        id="14"
                        bsSize="lg"
                        options={options5}
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center mb-4 margin-top-15">
                <h3 className="text-normal text-size-16">
                  Revisões realizadas
                </h3>
              </div>
              <div className="grid grid-cols-2 gap-6">
                <RevisaoCard
                  title="Assentamento"
                  km="5.000km"
                  horas="150h"
                  os="6789"
                  data="01/02/24"
                  horimetro="150:32:05"
                  kmRodado="5.854"
                  status="Carimbo Digital Marcado"
                />
                <RevisaoCard
                  title="Intermediária 1"
                  km="25.000km"
                  horas="400h"
                  os="6789"
                  data="01/02/24"
                  horimetro="401:32:05"
                  kmRodado="25.854"
                  status="Carimbo Digital Marcado"
                />
                <RevisaoCard
                  title="L1"
                  km="25.000km"
                  horas="400h"
                  os="6789"
                  data="01/02/24"
                  horimetro="401:32:05"
                  kmRodado="25.854"
                  status="Carimbo Digital Marcado"
                />
                <RevisaoCard
                  title="Intermediária 2"
                  km="25.000km"
                  horas="400h"
                  os="6789"
                  data="01/02/24"
                  horimetro="401:32:05"
                  kmRodado="25.854"
                  status="Carimbo Digital Marcado"
                />
                <RevisaoCard
                  title="L2"
                  km="25.000km"
                  horas="400h"
                  os="6789"
                  data="01/02/24"
                  horimetro="401:32:05"
                  kmRodado="25.854"
                  status="Carimbo Digital Marcado"
                />
                <RevisaoCard
                  title="Intermediária 3"
                  km="25.000km"
                  horas="400h"
                  os="6789"
                  data="01/02/24"
                  horimetro="401:32:05"
                  kmRodado="25.854"
                  status="Carimbo Digital Marcado"
                />
                <RevisaoCard
                  title="MP1"
                  km="25.000km"
                  horas="400h"
                  os="6789"
                  data="01/02/24"
                  horimetro="401:32:05"
                  kmRodado="25.854"
                  status="Carimbo Digital Marcado"
                />
              </div>

              <div className="flex justify-between items-center mb-4 margin-top-20">
                <h3 className="text-normal text-size-16">Próximas revisões</h3>
              </div>
              <div className="grid grid-cols-2 gap-6 scrollable">
                <RevisaoCard
                  title="Assentamento"
                  km="5.000km"
                  horas="150h"
                  os=""
                  data=""
                  horimetro=""
                  kmRodado=""
                  status="Carimbo Digital Marcado"
                />
                <RevisaoCard
                  title="Intermediária 1"
                  km="25.000km"
                  horas="400h"
                  os=""
                  data=""
                  horimetro=""
                  kmRodado=""
                  status="Carimbo Digital Marcado"
                />
              </div>
            </div>
          )}

          {activeTab === "trocaDeOleo" && (
            <div className="oil-change-content">
              <div className="flex justify-between items-center actions-row">
                <button type="button" className="btn btn-default btn-icon-only">
                  <span className="rioglyph rioglyph-tachograph-download"></span>
                </button>
                <div className="form-group">
                  <div className="display-flex gap-10 align-items-end">
                    <div className="form-group">
                      <Select
                        className="select-form"
                        id="14"
                        bsSize="lg"
                        options={options5}
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center mb-4 margin-top-15">
                <h3 className="text-normal text-size-16">
                  Trocas de óleo realizadas
                </h3>
              </div>

              <div className="grid grid-cols-2 gap-6">
                <RevisaoCard
                  title="L"
                  km="10.000km"
                  horas="200h"
                  os="1234"
                  data="10/05/24"
                  horimetro="200:00:00"
                  kmRodado="10.000"
                  status="Carimbo Digital Marcado"
                />
                <RevisaoCard
                  title="MP1"
                  km="20.000km"
                  horas="300h"
                  os="5678"
                  data="20/06/24"
                  horimetro="300:00:00"
                  kmRodado="20.000"
                  status="Carimbo Digital Marcado"
                />
              </div>

              <div className="flex justify-between items-center mb-4 margin-top-20">
                <h3 className="text-normal text-size-16">Próximas trocas</h3>
              </div>
              <div className="grid grid-cols-2 gap-6 scrollable">
                <RevisaoCard
                  title="MP2"
                  km="5.000km"
                  horas="150h"
                  os="6789"
                  data="01/02/24"
                  horimetro="150:32:05"
                  kmRodado="5.854"
                  status="Carimbo Digital Marcado"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const RevisaoCard: FC<RevisaoCardProps> = ({
  title,
  km,
  horas,
  os,
  data,
  horimetro,
  kmRodado,
  status,
}) => {
  return (
    <div className="border rounded-lg revision-card">
      <div className="flex justify-between items-center mb-2 px-4 border-b border-color-light">
        <p className="p-0 m-0 revision-card__title">{title}</p>
        <span className="rioglyph rioglyph-eye-option"></span>
      </div>

      <div className="flex justify-between mb-2 text-lg p-4 border-b border-color-light">
        <span className="text-size-14 text-color-dark">{km}</span>
        <span className="text-size-14 text-color-dark">{horas}</span>
      </div>

      <div className="form-group flex flex-wrap justify-between p-4 border-b border-color-light field-section">
        <label className="block w-24 text-size-12 text-color-dark">
          OS nº:
          <input
            type="text"
            value={os}
            readOnly
            className="form-control mt-1 p-2 border border-gray-300 rounded-md text-gray-700"
          />
        </label>
        <label className="block w-24 text-size-12 text-color-dark">
          Data:
          <input
            type="text"
            value={data}
            readOnly
            className="form-control mt-1 p-2 border border-gray-300 rounded-md text-gray-700"
          />
        </label>
      </div>
      <div className="form-group flex flex-wrap justify-between p-4 border-b border-color-light field-section">
        <label className="block w-full text-size-12 text-color-dark">
          Horímetro:
          <input
            type="text"
            value={horimetro}
            readOnly
            className="form-control mt-1 p-2 border border-gray-300 rounded-md text-gray-700"
          />
        </label>
        <label className="block w-full text-size-12 text-color-dark">
          Km:
          <input
            type="text"
            value={kmRodado}
            readOnly
            className="form-control mt-1 p-2 border border-gray-300 rounded-md text-gray-700"
          />
        </label>
      </div>

      <div className="flex justify-between items-center font-semibold text-color-primary text-teal-600 footer-card">
        <span className="w-3/4 text-size-12">{status}</span>
        <span className="w-1/4 text-size-20 rioglyph rioglyph-ok-circle"></span>
      </div>
    </div>
  );
};

export default PeriodicReviews;
