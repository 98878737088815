import { useState } from "react";
import Schedule from "./Schedule";
import Title from "./Title";
import CreateSchedulingDialog from "./dialogs/CreateSchedulingDialog";
import SchedulingDetailDialog from "./dialogs/SchedulingDetailDialog";
import { Scheduling } from "../../../../models/schedule";

interface IWeekCalendarProps {
    firstWeekDay: Date;
    lastWeekDay: Date;
    consultantMonthSchedule: Scheduling[];
    currentDate: Date;
    handleScheduleSidebar: () => void;
}

const WeekCalendar = (props: IWeekCalendarProps) => {
    const [createSchedulingDialog, setCreateSchedulingDialog] = useState(false);
    const handleCreateSchedulingDialog = () => setCreateSchedulingDialog(!createSchedulingDialog)

    const [scheduling, setScheduling] = useState<Scheduling | undefined>();
    const handleScheduling = (schedulingData: Scheduling | undefined) => setScheduling(schedulingData)

    return (
        <div>
            <Title
                {...props} 
                handleCreateSchedulingDialog={handleCreateSchedulingDialog} 
            />
            <Schedule 
                {...props} 
                handleCreateSchedulingDialog={handleCreateSchedulingDialog} 
                handleScheduling={handleScheduling}
            />

            <CreateSchedulingDialog 
                show={createSchedulingDialog} 
                onClose={() => setCreateSchedulingDialog(false)}
            />

            <SchedulingDetailDialog
                show={!!scheduling}
                onClose={() => setScheduling(undefined)}
                scheduling={scheduling}
            />
        </div>
    );
}

export default WeekCalendar;