import Page from "../Page"

const MaintenanceMonitoringContainer = () => {
	return (
		<Page>
			<div>
				<h1>Maintenance Monitoring</h1>
				<p>Monitor maintenance tasks for your vehicles.</p>
			</div>
		</Page>
	);
}

export default MaintenanceMonitoringContainer;