import { useState } from "react";
import Checkbox from "@rio-cloud/rio-uikit/Checkbox";
import DatePicker from "@rio-cloud/rio-uikit/DatePicker";
import type { Moment } from "moment";
import "./styles.scss";

const ChatMessages = () => {
  const [currentView, setCurrentView] = useState<
    "list" | "chat" | "newChat" | "scheduling"
  >("list");
  const [, setCurrentChat] = useState<string | null>(null);
  const [newScheduling, setNewScheduling] = useState(false);

  const handleOpenMessages = (chatName: string) => {
    setCurrentChat(chatName);
    setCurrentView("chat");
  };

  const handleBackToList = () => {
    setCurrentChat(null);
    setCurrentView("list");
  };

  const handleNewChat = () => {
    setCurrentView("newChat");
  };

  const handleOpenScheduling = () => {
    setCurrentView("scheduling");
  };

  return (
    <div className="chat-sidebar">
      {currentView === "list" ? (
        <>
          <h3 className="flex justify-between items-center gap-6 chat-sidebar__title">
            Mensagens
            <button
              type="button"
              className="btn btn-primary btn-link p-0"
              onClick={handleNewChat}
            >
              <span className="rioglyph rioglyph-plus"></span>
            </button>
          </h3>

          <div className="search-field">
            <div className="form-group">
              <label className="width-100pct">
                <input
                  className="form-control margin-y-5"
                  type="text"
                  placeholder="Pesquisar"
                />
                <button className="btn btn-action padding-0">
                  <span className="rioglyph rioglyph-search text-color-primary"></span>
                </button>
              </label>
            </div>
          </div>

          <div className="chats-list">
            <div
              className="flex items-center justify-between gap-6 travels-scheduling"
              onClick={handleOpenScheduling}
            >
              <span className="rioglyph rioglyph-calendar text-color-primary"></span>
              <div className="flex flex-column travels-scheduling__info">
                <span className="text-color-black text-size-18 text-medium">
                  Agendamentos
                </span>
                <span className="text-color-black text-size-14">
                  1 pendente
                </span>
              </div>
              <span className="rioglyph rioglyph-chevron-right text-color-primary"></span>
            </div>

            <div
              onClick={() => handleOpenMessages("Lays Silva")}
              className="flex items-center justify-between card-info"
            >
              <div className="card-info__photo w-1/6">
                <img src="assets/images/foto-1.png" alt="" />
                <span className="card-info__alert"></span>
              </div>
              <div className="flex flex-column w-4/6 card-info__name">
                <span className="text-color-black text-size-18 text-medium">
                  Lays Silva
                </span>
                <span className="text-color-black text-size-14 text-medium">
                  Consultora
                </span>
              </div>
              <div className="card-info__time w-1/6">
                <span>10 min</span>
              </div>
            </div>

            <div className="flex items-center justify-between card-info">
              <div className="card-info__photo w-1/6">
                <img src="assets/images/foto-2.png" alt="" />
                <span className="card-info__alert"></span>
              </div>

              <div className="flex flex-column w-4/6 card-info__name">
                <span className="text-color-black text-size-18 text-medium">
                  Renato Farias Neto
                </span>
                <span className="text-color-black text-size-14 text-medium">
                  Consultor
                </span>
              </div>

              <div className="card-info__time w-1/6">
                <span>2 h</span>
              </div>
            </div>

            <div className="flex items-center justify-between card-info">
              <div className="card-info__photo w-1/6">
                <img src="assets/images/foto-3.png" alt="" />
              </div>

              <div className="flex flex-column w-4/6 card-info__name">
                <span className="text-color-black text-size-18">
                  José da Silva Rocha
                </span>
                <span className="text-color-black text-size-14">Consultor</span>
              </div>

              <div className="card-info__time w-1/6">
                <span>2 d</span>
              </div>
            </div>

            <div className="flex items-center justify-between card-info">
              <div className="card-info__photo w-1/6">
                <img src="assets/images/foto-4.png" alt="" />
              </div>

              <div className="flex flex-column w-4/6 card-info__name">
                <span className="text-color-black text-size-18">
                  Vanderlei Peixoto
                </span>
                <span className="text-color-black text-size-14">Consultor</span>
              </div>

              <div className="card-info__time w-1/6">
                <span>4 d</span>
              </div>
            </div>

            <div className="flex items-center justify-between card-info">
              <div className="card-info__photo w-1/6">
                <img src="assets/images/foto-4.png" alt="" />
              </div>

              <div className="flex flex-column w-4/6 card-info__name">
                <span className="text-color-black text-size-18">
                  Carlos Souza Destro
                </span>
                <span className="text-color-black text-size-14">Consultor</span>
              </div>

              <div className="card-info__time w-1/6">
                <span>7 d</span>
              </div>
            </div>
          </div>
        </>
      ) : currentView === "chat" ? (
        <div className="chat-view flex flex-col h-full">
          <div className="chat-header flex justify-between items-center bg-black text-white px-4 py-2">
            <button
              onClick={handleBackToList}
              className="text-white focus:outline-none"
            >
              <span className="rioglyph rioglyph-arrow-left text-color-primary"></span>
            </button>
            <h3 className="text-size-h4 font-bold insert-title">Mensagens</h3>
          </div>

          <div className="chat-body">
            <div className="flex items-center open-call">
              <span className="rioglyph rioglyph-pencil-square text-color-primary text-size-h4"></span>
              <span className="text-size-14 text-medium open-call__text">
                Abertura de chamado para Meteor
              </span>
            </div>

            <div className="chat-details flex items-center gap-6 px-4 py-3">
              <img
                src="assets/images/foto-1.png"
                alt="Foto de Lays Silva"
                className="w-20 h-20 rounded-full w-1/6"
              />
              <div className="flex flex-col w-4/6">
                <span className="text-size-12 text-color-status-resting">
                  Consultora
                </span>
                <span className="text-size-18 text-color-black font-medium">
                  Lays Silva
                </span>
              </div>
              <span className="flex justify-end w-1/6">
                <img src="assets/images/more.svg" alt="" />
              </span>
            </div>

            <div className="chat-messages flex flex-col-reverse gap-4 p-4 overflow-y-auto">
              <p className="message received mb-4">
                <span className="block message-text text-white px-3 py-2 max-w-max">
                  Pode fazer?
                  <span className="block text-xs mt-1">14:09</span>
                </span>
              </p>
              <p className="message received mb-4">
                <span className="block message-text text-white px-3 py-2 max-w-max">
                  Vi que precisa trocar a palheta do limpador de para-brisa,
                  fica R$60 com a mão de obra.
                  <span className="block text-xs mt-1">14:21</span>
                </span>
              </p>
              <p className="message sent mb-4 text-right">
                <span className="block message-text text-white px-3 py-2 max-w-max ml-auto">
                  Opa, boa tarde
                  <span className="block text-xs mt-1">14:10</span>
                </span>
              </p>
              <p className="message received mb-4">
                <span className="block message-text text-white px-3 py-2 max-w-max">
                  Oi Henrique!
                  <span className="block text-xs mt-1">14:09</span>
                </span>
              </p>
            </div>

            <div className="chat-input flex items-center gap-5 p-4 border-t bg-white">
              <input
                type="text"
                className="flex-grow px-4 py-2 border rounded-full text-size-14 focus:outline-none focus:ring-2 focus:ring-teal-600"
                placeholder="Mensagem"
              />

              <button type="button" className="btn btn-action padding-0">
                <img src="assets/images/button-voice.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
      ) : currentView === "newChat" ? (
        <div className="chat-view new-chat flex flex-col h-full">
          <div className="chat-header flex justify-between items-center bg-black text-white px-4 py-2">
            <button
              onClick={handleBackToList}
              className="text-white focus:outline-none"
            >
              <span className="rioglyph rioglyph-arrow-left text-color-primary"></span>
            </button>
            <h3 className="text-size-h4 font-bold insert-title">
              Nova Mensagem
            </h3>
          </div>

          <div className="search-field mt-10">
            <div className="form-group margin-bottom-0">
              <label className="width-100pct text-color-black text-medium">
                Convidar pessoas
                <input
                  className="form-control margin-y-5"
                  type="text"
                  placeholder="Pesquisar"
                />
                <button className="btn btn-action padding-0">
                  <span className="rioglyph rioglyph-search text-color-primary"></span>
                </button>
              </label>
            </div>
          </div>

          <div className="new-chat-body p-4">
            <div className="flex items-center justify-between card-info">
              <div className="card-info__photo w-1/6">
                <img src="assets/images/foto-2.png" alt="" />
              </div>
              <div className="flex flex-column w-4/6 card-info__name">
                <span className="text-color-black text-size-18">
                  Renato Farias Neto
                </span>
                <span className="text-color-black text-size-14">Consultor</span>
              </div>
              <div className="card-info__time w-1/6 flex justify-end">
                <Checkbox />
              </div>
            </div>

            <div className="flex items-center justify-between card-info">
              <div className="card-info__photo w-1/6">
                <img src="assets/images/foto-2.png" alt="" />
              </div>
              <div className="flex flex-column w-4/6 card-info__name">
                <span className="text-color-black text-size-18">
                  Renato Farias Neto
                </span>
                <span className="text-color-black text-size-14">Consultor</span>
              </div>
              <div className="card-info__time w-1/6 flex justify-end">
                <Checkbox />
              </div>
            </div>

            <div className="flex items-center justify-between card-info">
              <div className="card-info__photo w-1/6">
                <img src="assets/images/foto-2.png" alt="" />
              </div>
              <div className="flex flex-column w-4/6 card-info__name">
                <span className="text-color-black text-size-18">
                  Renato Farias Neto
                </span>
                <span className="text-color-black text-size-14">Consultor</span>
              </div>
              <div className="card-info__time w-1/6 flex justify-end">
                <Checkbox />
              </div>
            </div>

            <div className="flex items-center justify-between card-info">
              <div className="card-info__photo w-1/6">
                <img src="assets/images/foto-2.png" alt="" />
              </div>
              <div className="flex flex-column w-4/6 card-info__name">
                <span className="text-color-black text-size-18">
                  Renato Farias Neto
                </span>
                <span className="text-color-black text-size-14">Consultor</span>
              </div>
              <div className="card-info__time w-1/6 flex justify-end">
                <Checkbox />
              </div>
            </div>

            <div className="flex items-center justify-between card-info">
              <div className="card-info__photo w-1/6">
                <img src="assets/images/foto-2.png" alt="" />
              </div>
              <div className="flex flex-column w-4/6 card-info__name">
                <span className="text-color-black text-size-18">
                  Renato Farias Neto
                </span>
                <span className="text-color-black text-size-14">Consultor</span>
              </div>
              <div className="card-info__time w-1/6 flex justify-end">
                <Checkbox />
              </div>
            </div>
          </div>

          <div className="new-chat-footer flex justify-center gap-5">
            <button type="button" className="btn">
              <span className="text-capitalize text-color-primary">
                Cancelar
              </span>
            </button>
            <button type="button" className="btn btn-primary">
              <span className="text-capitalize">Criar conversa</span>
            </button>
          </div>
        </div>
      ) : currentView === "scheduling" ? (
        <div className="chat-view scheduling-view flex flex-col h-full">
          <div className="chat-header flex justify-between items-center bg-black text-white px-4 py-2">
            <button
              onClick={handleBackToList}
              className="text-white focus:outline-none"
            >
              <span className="rioglyph rioglyph-arrow-left text-color-primary"></span>
            </button>
            <h3 className="text-size-h4 font-bold insert-title">
              Agendamentos
            </h3>
          </div>

          <div className="chat-body scheduling-content mt-10">
            <div className="chat-details flex items-center gap-6 px-4 py-3">
              <img
                src="assets/images/foto-1.png"
                alt="Foto de Lays Silva"
                className="w-20 h-20 rounded-full w-1/6"
              />
              <div className="flex flex-col w-5/6">
                <span className="text-size-12 text-color-status-resting">
                  Enviada há 1 hora por
                </span>
                <span className="text-size-18 text-color-black font-medium">
                  Lays Silva
                </span>
                <span className="text-color-white text-size-10 bg-map-marker-asset consultor-info">
                  <span className="rioglyph rioglyph-businessman text-color-white text-size-12 padding-right-5"></span>
                  Consultora Maggi Jundiaí
                </span>
              </div>
            </div>

            <div className="scheduling-details">
              <p className="text-color-secondary text-size-16 text-medium padding-0 m-0">
                20/10/2024 às 09:00
              </p>
              <p className="text-color-black text-size-20 text-bold m-0">
                Maggi Jundiaí, SP, Brasil
              </p>
              <p className="text-color-black text-size-16 m-0">
                Meteor VW29.530 <span>.</span> 9536G8241LR021210
              </p>
            </div>

            <div className="scheduling-actions flex justify-center gap-6 mt-10">
              <button type="button" className="btn btn-default btn-lg">
                <span className="text-capitalize">Recusar</span>
              </button>
              <button type="button" className="btn btn-primary btn-lg">
                <span className="text-capitalize">Aceitar</span>
              </button>
            </div>

            <div
              className="new-date-suggestions flex justify-center items-center mt-10 pt-5 cursor-pointer"
              onClick={() => setNewScheduling(true)}
            >
              <span className="rioglyph rioglyph-calendar text-color-primary"></span>
              <span className="text-color-primary text-size-20 text-medium">
                Sugerir novo dia e horário
              </span>
            </div>

            {newScheduling ? (
              <div className="new-date-section mt-10 pt-5">
                <p className="text-color-black text-medium text-size-14">
                  Nova sugestão de dia e horário
                </p>
                <div className="">
                  <div className="col-12">
                    <label
                      htmlFor="2"
                      className="display-flex flex-wrap justify-content-between"
                    >
                      <span>Data</span>
                    </label>
                    <DatePicker
                      id="2"
                      inputProps={{ placeholder: "Selecione a data" }}
                      dateFormat="DD-MM-YYYY"
                      timeFormat={false}
                      onChange={(result: Moment | string) =>
                        console.log(result)
                      }
                    />
                  </div>

                  <div className="col-12">
                    <label htmlFor="4">Horário</label>
                    <DatePicker
                      inputProps={{ placeholder: "Escolha o horário", id: "4" }}
                      dateFormat={false}
                      alignRight
                      onChange={(result: Moment | string) =>
                        console.log(result)
                      }
                    />
                  </div>
                </div>

                <div className="scheduling-actions flex justify-center gap-6 mt-10">
                  <button type="button" className="btn btn-default btn-lg">
                    <span className="text-capitalize">Cancelar</span>
                  </button>
                  <button type="button" className="btn btn-primary btn-lg">
                    <span className="text-capitalize">Enviar sugestão</span>
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ChatMessages;
