import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import ApplicationLayoutBodyNavigation from "@rio-cloud/rio-uikit/ApplicationLayoutBodyNavigation";
import SubNavigation from "@rio-cloud/rio-uikit/SubNavigation";
import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

interface ISubnavitationHeaderProps {
  children: ReactNode;
}

const SubNavigationHeader = ({ children }: ISubnavitationHeaderProps) => {
  const bodyNavigationClasses = ""; // isMobile ? '' : 'has-offset';

  return (
    <ApplicationLayout.Body
      className="uikitDemo"
      navigation={
        <ApplicationLayoutBodyNavigation className={bodyNavigationClasses}>
          <SubNavigation
            navItems={[
              {
                key: "Manutenções preventivas",
                route: <NavLink to="/">Manutenções preventivas</NavLink>,
              },
              {
                key: "2",
                route: <NavLink to="/2">Acompanhamento da manutenção</NavLink>,
              },
              {
                key: "Meus agendamentos",
                route: (
                  <NavLink to="/meus-agendamentos">Meus agendamentos</NavLink>
                ),
              },
              {
                key: "4",
                route: <NavLink to="/4">Histórico</NavLink>,
              },
            ]}
          />
        </ApplicationLayoutBodyNavigation>
      }
    >
      {children}
    </ApplicationLayout.Body>
  );
};

export default SubNavigationHeader;
