import { useEffect, useState } from "react";
import StatsWidgets from "@rio-cloud/rio-uikit/StatsWidgets";
import { Scheduling } from "../../../../models/schedule";
import OccupancyRate from "./OccupancyRate";
import SchedulingCounts from "./SchedulingCounts";
import { getMaintenanceWeekSchedule, getWeeklyOccupancyRate, getWeeklySchedulingCounts } from "../../../../services/consultantScheduleService";

interface IBigNumbersProps {
    consultantMonthSchedule: Scheduling[];
    firstWeekDay: Date;
}

const BigNumbers = ({ consultantMonthSchedule, firstWeekDay }: IBigNumbersProps) => {
    const [occupancyRate, setOccupancyRate] = useState<number>(0);
    const [preventiveCount, setPreventiveCount] = useState<number>(0);
    const [correctiveCount, setCorrectiveCount] = useState<number>(0);

    useEffect(() => {
        const fetchData = async () => {
            const weekSchedule = await getMaintenanceWeekSchedule(firstWeekDay, consultantMonthSchedule);
            setOccupancyRate(getWeeklyOccupancyRate(weekSchedule));
            const [preventive, corrective] = getWeeklySchedulingCounts(weekSchedule);
            setPreventiveCount(preventive);
            setCorrectiveCount(corrective);
        }

        fetchData();
    }, [consultantMonthSchedule]);

    return (
        <div className="padding-bottom-50 display-flex justify-content-center">
            <StatsWidgets>
                <OccupancyRate occupancyRate={occupancyRate} />
                <SchedulingCounts preventiveCount={preventiveCount} correctiveCount={correctiveCount} />
            </StatsWidgets>
        </div>
    );
}

export default BigNumbers;
