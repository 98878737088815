import { IMonthCalendarProps } from '.';
import { getCurrentMonthDays, isDayInMonth } from '../../../../utils/dateUtils';

const Calendar = ({ dayChoosed }: IMonthCalendarProps) => {
	const days = getCurrentMonthDays();
	const weekdays = ['s', 't', 'q', 'q', 's', 's', 'd'];
	const now = new Date();

	return (
		<div>
			<div className='text-capitalize-first-word text-size-16 padding-x-15 padding-y-10 text-medium'>
				{now.toLocaleString('pt-BR', { month: 'long' })}
			</div>
			<div className="padding-10">
				<div className="display-grid gap-20 grid-cols-7 width-250">
					{[...weekdays, ...days].map((day, index) => (
						<div
							key={index}
							style={{ lineHeight: '25px' }}
							className={`
								height-25 width-25 text-center text-size-10 text-medium
								${index < 7 ? 'text-color-dark' : ''} 
								${index >= 7 && !isDayInMonth(day) ? 'text-color-gray' : ''}
								${dayChoosed.getDate() === day ? 'bg-highlight-darker text-color-white rounded-circle' : ''}
							`}
						>
							{day.toString().replace(/[-+]/g, '')}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Calendar;