import { useState } from "react";
import Dialog from "@rio-cloud/rio-uikit/Dialog";
import Button from "@rio-cloud/rio-uikit/Button";
import "./styles.scss";
import ScheduleDetails from "../scheduleDetails";

interface IICalendarProps {
  closeSidebar: () => void;
}

const Calendar = ({ closeSidebar }: IICalendarProps) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [showDialog, setShowDialog] = useState(false);

  const daysOfWeek = ["S", "T", "Q", "Q", "S", "S", "D"];
  const monthNames = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const changeMonth = (direction: any) => {
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + direction,
      1
    );
    setCurrentDate(newDate);
  };

  const renderDays = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1).getDay();
    const lastDate = new Date(year, month + 1, 0).getDate();

    const days = [];
    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }

    for (let date = 1; date <= lastDate; date++) {
      const isToday =
        date === currentDate.getDate() &&
        month === new Date().getMonth() &&
        year === new Date().getFullYear();

      days.push(
        <div
          key={date}
          className={`text-size-14 font-weight-normal text-color-map-marker-asset calendar-day ${
            isToday ? "today" : ""
          }`}
          onClick={() =>
            console.log(`Data selecionada: ${date}/${month + 1}/${year}`)
          }
        >
          {date}
        </div>
      );
    }

    return days;
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  return (
    <>
      <div className="calendar-sidebar">
        <div className="flex justify-between padding-right-20 calendar-sidebar__header">
          <h3 className="flex items-center gap-6 chat-sidebar__title text-color-map-marker-asset">
            <span className="rioglyph rioglyph-calendar text-color-map-marker-asset"></span>
            Calendário
          </h3>
          <div
            className="padding-top-20 close-sidebar cursor-pointer"
            onClick={() => closeSidebar()}
          >
            <span className="rioglyph rioglyph-remove"></span>
          </div>
        </div>

        <div className="calendar-container">
          <div className="calendar-header">
            <span className="text-color-status-available text-normal text-size-16">
              {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
            </span>
            <div className="calendar-header__nav">
              <button
                className="rioglyph rioglyph-chevron-left text-size-14"
                onClick={() => changeMonth(-1)}
              />
              <button
                className="rioglyph rioglyph-chevron-right text-size-14"
                onClick={() => changeMonth(1)}
              />
            </div>
          </div>
          <div className="calendar-days-of-week">
            {daysOfWeek.map((day, index) => (
              <div key={index} className="calendar-day-name">
                {day}
              </div>
            ))}
          </div>
          <div className="calendar-days">{renderDays()}</div>
        </div>

        <div className="schedule-container">
          <h3 className="flex items-center gap-4 chat-sidebar__title text-color-darker">
            <div className="border-b w-full pb-1">
              <span className="rioglyph rioglyph-checkboxes text-color-darker"></span>
              Agendamento
            </div>
          </h3>

          <div
            className="alert alert-dismissible alert-info cursor-pointer my-10"
            onClick={() => setShowDialog(true)}
          >
            <div className="display-flex gap-10">
              <div className="w-full">
                <div className="alert-header flex justify-between">
                  <strong className="text-size-16">Revisão preventiva</strong>
                  <strong className="text-size-16">12:00</strong>
                </div>
                <div className="margin-y-5 flex flex-column">
                  <span>Meteor VW29.530</span>
                  <span>Chassi: 9536G8241LR021210</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        show={showDialog}
        title="Detalhes do agendamento"
        subtitle="Confira abaixo as informações do agendamento:"
        footer={
          <Button
            onClick={handleCloseDialog}
            className="btn btn-primary"
            bsSize="lg"
          >
            Fechar
          </Button>
        }
        body={<ScheduleDetails />}
        onClose={handleCloseDialog}
        className="dialog-agendamento"
        showCloseButton
        bsSize="lg"
      />
    </>
  );
};

export default Calendar;
