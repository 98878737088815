import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./styles.scss";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ScheduleModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  return (
    <div className="scheduleModal">
      <div className="bg-white shadow-lg scheduleModal__wrapper">
        <div className="flex justify-between items-center border-b scheduleModal__header">
          <h2 className="text-size-20 font-normal m-0 p-0">Agendamento</h2>
          <button
            onClick={onClose}
            className="text-color-black text-size-h2 hover:text-gray-800"
          >
            &times;
          </button>
        </div>

        {/* Options */}
        <div className="flex flex-column gap-6 scheduleBody">
          {/* Option 1 */}
          <div className="border scheduleBody__card">
            <h3 className="text-center text-size-h2 font-medium">
              <span className="font-bold text-color-info">Volks</span>
              <span className="text-color-status-resting"> | Total</span>
            </h3>
            <p className="text-center text-size-16 text-color-black mt-2 margin-bottom-20">
              Quero agendar com VolksTotal
            </p>
            <button className="btn btn-primary text-size-16">Continuar</button>
          </div>

          {/* Option 2 */}
          <div className="border scheduleBody__card">
            <div className="flex justify-center margin-bottom-10">
              <span className="rioglyph rioglyph-workshop text-size-h1"></span>
            </div>
            <p className="text-center text-size-16 text-color-black mt-2 margin-bottom-20">
              Quero simplesmente agendar
            </p>
            <button className="btn btn-primary text-size-16 btn-scheduling">
              <NavLink to="/meus-agendamentos">Continuar</NavLink>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleModal;
