import { useState } from "react";
import omit from "lodash/fp/omit";
import classNames from "classnames";
import TableToolbar from "@rio-cloud/rio-uikit/TableToolbar";
import type TableViewTogglesViewType from "@rio-cloud/rio-uikit/TableViewToggles";
import TableSearch from "@rio-cloud/rio-uikit/TableSearch";
import TableSettingsDialog from "@rio-cloud/rio-uikit/TableSettingsDialog";
import SortArrows from "@rio-cloud/rio-uikit/SortArrows";
import { naturalSortByProperty } from "@rio-cloud/rio-uikit/SortUtils";
import "./styles.scss";
import VehicleAlerts from "../vehicleAlerts";
import PeriodicReviews from "../periodicReviews";
import Inspections from "../inspections";
import ScheduleModal from "../scheduleModal";
import Tooltip from "@rio-cloud/rio-uikit/Tooltip";
import OverlayTrigger from "@rio-cloud/rio-uikit/OverlayTrigger";
import Button from "@rio-cloud/rio-uikit/Button";
import Dialog from "@rio-cloud/rio-uikit/Dialog";

const defaultColumnOrder = [
  "agendamento",
  "veiculo",
  "chassi",
  "odometro",
  "horimetro",
];

const disabledColumns = ["vin"];

type ColumnLabel = {
  [key: string]: string;
};

const columnLabels: ColumnLabel = {
  agendamento: "Agendamento",
  veiculo: "Veículo",
  chassi: "Chassi",
  odometro: "Odômetro",
  horimetro: "Horímetro",
};

type ColumnDetails = {
  [key: string]: number;
};

type ColumnDetailsMap = {
  [key: string]: ColumnDetails;
};

const demoColumnsDetails: ColumnDetailsMap = {
  agendamento: {
    width: 60,
    defaultWidth: 60,
    maxWidth: 300,
  },
  veiculo: {
    width: 0,
    defaultWidth: 200,
    maxWidth: 350,
  },
  chassi: {
    width: 0,
    defaultWidth: 0,
    maxWidth: 350,
  },
  odometro: {
    width: 0,
    defaultWidth: 0,
    maxWidth: 350,
  },
  horimetro: {
    width: 0,
    defaultWidth: 0,
    maxWidth: 350,
  },
};

type Vehicle = {
  agendamento: boolean;
  veiculo: React.ReactNode;
  chassi: React.ReactNode;
  odometro: string;
  horimetro: string;
};

const vehicleList: Vehicle[] = [
  {
    agendamento: false,
    veiculo: "VW29.530 Meteor",
    chassi: "9536G8241LR021210",
    odometro: "5.000 KM",
    horimetro: "1.357:26:59",
  },
  {
    agendamento: false,
    veiculo: "VW29.530 Meteor",
    chassi: "9536G8241LR021210",
    odometro: "5.000 KM",
    horimetro: "2.034:26:59",
  },
  {
    agendamento: false,
    veiculo: "VW20.480 Constellation",
    chassi: "9536G8241LR021210",
    odometro: "14.000 KM",
    horimetro: "3.508:26:59",
  },
  {
    agendamento: true,
    veiculo: "e-Delivery 11 e-Delivery",
    chassi: "9536G8241LR021210",
    odometro: "5.000 KM",
    horimetro: "456:26:59",
  },
  {
    agendamento: true,
    veiculo: "e-Delivery 11 e-Delivery",
    chassi: "9536G8241LR021210",
    odometro: "224.102 KM",
    horimetro: "2.034:26:59",
  },
  {
    agendamento: true,
    veiculo: "VW29.530 Meteor",
    chassi: "9536G8241LR021210",
    odometro: "81.580 KM",
    horimetro: "147:26:59",
  },
];

export type TableCommonDemoProps = {
  viewType: typeof TableViewTogglesViewType;
};

const secondTableColumnOrder = [
  "acoes",
  "revisoes",
  "score",
  "grupoManutencao",
  "statusGarantia",
  "rio",
  "status",
  "volksTotal",
  "ultimaRevisao",
  "proximaRevisao",
];

const secondTableColumnsDetails: ColumnDetailsMap = {
  acoes: {
    width: 150,
    defaultWidth: 150,
    maxWidth: 150,
  },
  revisoes: {
    width: 120,
    defaultWidth: 120,
    maxWidth: 150,
  },
  score: {
    width: 100,
    defaultWidth: 100,
    maxWidth: 150,
  },
  grupoManutencao: {
    width: 130,
    defaultWidth: 130,
    maxWidth: 150,
  },
  statusGarantia: {
    width: 100,
    defaultWidth: 100,
    maxWidth: 100,
  },
  rio: {
    width: 80,
    defaultWidth: 80,
    maxWidth: 100,
  },
  status: {
    width: 80,
    defaultWidth: 80,
    maxWidth: 100,
  },
  volksTotal: {
    width: 100,
    defaultWidth: 100,
    maxWidth: 120,
  },
  ultimaRevisao: {
    width: 140,
    defaultWidth: 140,
    maxWidth: 160,
  },
  proximaRevisao: {
    width: 140,
    defaultWidth: 140,
    maxWidth: 160,
  },
};

const secondTableColumnLabels: ColumnLabel = {
  acoes: "Campanhas de Campo",
  revisoes: "Revisões Periódicas",
  score: "Vehicle score",
  grupoManutencao: "Grupo Manutencao",
  statusGarantia: "Status Garantia",
  rio: "RIO",
  status: "Status",
  volksTotal: "Volks Total",
  ultimaRevisao: "Última Revisão",
  proximaRevisao: "Próxima Revisão",
};

type Product = {
  acoes: string;
  revisoes: string;
  score: number;
  grupoManutencao: string;
  statusGarantia: string;
  rio: string;
  status: string;
  volksTotal: string;
  ultimaRevisao: [{ value: string; status: string }];
  proximaRevisao: [{ value: string; status: string }];
};

const secondTableList: Product[] = [
  {
    acoes: "Notebook",
    revisoes: "5",
    score: 57,
    grupoManutencao: "Rodoviário",
    statusGarantia: "Em Garantia",
    rio: "Premium",
    status: "Online",
    volksTotal: "",
    ultimaRevisao: [
      {
        value: "",
        status: "",
      },
    ],
    proximaRevisao: [
      {
        value: "Assentamento",
        status: "Disponível para agendar",
      },
    ],
  },
  {
    acoes: "Notebook",
    revisoes: "5",
    score: 74,
    grupoManutencao: "Rodoviário",
    statusGarantia: "Em Garantia",
    rio: "Basic",
    status: "Offline",
    volksTotal: "Prev",
    ultimaRevisao: [
      {
        value: "Intermediária 3",
        status: "Realizada",
      },
    ],
    proximaRevisao: [
      {
        value: "MP1",
        status: "Disponível para agendar",
      },
    ],
  },
  {
    acoes: "Notebook",
    revisoes: "5",
    score: 23,
    grupoManutencao: "Especial",
    statusGarantia: "Em Garantia",
    rio: "",
    status: "-",
    volksTotal: "Plus",
    ultimaRevisao: [
      {
        value: "Assentamento",
        status: "Realizada",
      },
    ],
    proximaRevisao: [
      {
        value: "Intermediária 1",
        status: "Disponível para agendar",
      },
    ],
  },
  {
    acoes: "Notebook",
    revisoes: "5",
    score: 12,
    grupoManutencao: "Rodoviário",
    statusGarantia: "Expirada",
    rio: "E-fleet",
    status: "Online",
    volksTotal: "Max",
    ultimaRevisao: [
      {
        value: "L3",
        status: "Realizada",
      },
    ],
    proximaRevisao: [
      {
        value: "Intermediária 5",
        status: "Agendada para 16/10/2024",
      },
    ],
  },
  {
    acoes: "Notebook",
    revisoes: "5",
    score: 98,
    grupoManutencao: "Misto",
    statusGarantia: "Expirada",
    rio: "Premium",
    status: "Offline",
    volksTotal: "Prev",
    ultimaRevisao: [
      {
        value: "Intermediária 3",
        status: "Vencido",
      },
    ],
    proximaRevisao: [
      {
        value: "Intermediária 2",
        status: "",
      },
    ],
  },
  {
    acoes: "Notebook",
    revisoes: "5",
    score: 44,
    grupoManutencao: "Severo",
    statusGarantia: "Expirada",
    rio: "",
    status: "-",
    volksTotal: "",
    ultimaRevisao: [
      {
        value: "Intermediária 3",
        status: "Vencido",
      },
    ],
    proximaRevisao: [
      {
        value: "MP1",
        status: "",
      },
    ],
  },
];

const RevisionPlan: React.FC = () => {
  return (
    <div className="w-full mx-auto bg-white rounded-lg p-6">
      <h1 className="text-size-20 font-bold mb-4">Plano de Revisão</h1>

      <section className="margin-bottom-20">
        <h2 className="text-size-16 font-semibold mb-2">
          Revisão de Assentamento entre 1.000 e 5.000 km
        </h2>
        <ul className="list-disc list-inside space-y-1">
          <li>Códigos de falha</li>
          <li>Óleo do motor (nível e vazamentos)</li>
          <li>Braçadeiras de exaustão do turbo compressor</li>
          <li>Grampos e suportes das molas (inclusive 3º eixo)</li>
          <li>Eixos cames, ajustadores e árvore de transmissão</li>
          <li>Bateria(s): nível, densidade do eletrólito e tensão</li>
          <li>
            Iluminação externa - lanternas, luz alta e baixa dos faróis; luzes
            de freio; luzes de ré e luzes de emergência
          </li>
          <li>
            Terminais elétricos das baterias, motor de partida, alternador,
            conexões à massa, sensores e chicotes elétricos
          </li>
        </ul>
      </section>

      <section className="margin-bottom-20">
        <h2 className="text-size-16 font-semibold mb-2">
          Revisão Intermediária
        </h2>
        <ul className="list-disc list-inside space-y-1">
          <li>Filtro separador de água do combustível</li>
          <li>Filtro principal de combustível</li>
          <li>Ajustador do freio</li>
          <li>Filtro secador de ar dos freios</li>
        </ul>
      </section>

      <section className="margin-bottom-20">
        <h2 className="text-size-16 font-semibold mb-2">
          Revisão de Lubrificação
        </h2>
        <ul className="list-disc list-inside space-y-1">
          <li>Códigos de falha</li>
          <li>
            Entrada de ar para aquecimento/ventilação/sistema de ar condicionado
          </li>
          <li>Funcionamento do ar condicionado</li>
          <li>
            Sistema de arrefecimento: troca do líquido conforme tabela de
            trabalhos complementares
          </li>
          <li>Filtro separador de água do combustível</li>
          <li>
            Lonas dos freios: desgaste das lonas de freio e funcionamento do
            ajustador automático
          </li>
          <li>
            Rodas e pneus: porcas das rodas, estado e pressão dos pneus
            (inclusive estepe)
          </li>
          <li>Quinta-roda - sapata, disco de fricção e mesa</li>
          <li>
            Iluminação externa - lanternas, luz alta e baixa dos faróis; luzes
            de freio; luzes de ré e luzes de emergência
          </li>
          <li>Bateria(s): nível, densidade do eletrólito e tensão</li>
          <li>Motor: óleo, filtro e vazamentos</li>
          <li>Filtro principal de combustível</li>
          <li>Tubulações de combustível e suas fixações</li>
          <li>Filtro de Arla 32 do sistema SCR</li>
          <li>Tubulação entre o filtro de ar e o motor</li>
          <li>Tela da bomba de escorva</li>
          <li>Filtro de ar do motor</li>
          <li>Filtro secundário de ar do motor (quando equipado)</li>
          <li>Filtro secador de ar dos freios</li>
          <li>Respiros dos diferenciais</li>
          <li>Válvulas do motor</li>
          <li>Buchas dos tirantes longitudinais e tirantes “V”</li>
          <li>Filtro da ventilação positiva do cárter (“blow-by”)</li>
          <li>Caixa de mudanças</li>
          <li>Tomada de força auxiliar (se equipado)</li>
          <li>
            Sistema de direção: verificar vazamentos, coifas, articulações
            esféricas e nível do fluido
          </li>
          <li>Eixo traseiro</li>
          <li>Árvore de transmissão - juntas universais e luvas deslizantes</li>
          <li>
            Terminais elétricos das baterias, motor de partida, alternador,
            conexões à massa, sensores e chicotes elétricos
          </li>
          <li>Suspensão pneumática</li>
          <li>Terceiro eixo (somente veículos 6x2)</li>
          <li>Eixo traseiro (anterior e posterior - 6x4 / trativo - 6x2)</li>
        </ul>
      </section>
    </div>
  );
};

const TableCommonDemo = () => {
  const [searchValue, setSearchValue] = useState("");
  const [sortBy] = useState<keyof Vehicle>("veiculo");
  const [showTableSettingsDialog, setShowTableSettingsDialog] = useState(false);
  const [columnOrder, setColumnOrder] = useState<string[]>(defaultColumnOrder);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [columnsDetails, setColumnsDetails] = useState(demoColumnsDetails);
  const [secondTableColumns, setSecondTableColumns] = useState(
    secondTableColumnsDetails
  );
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [sidebarContent, setSidebarContent] = useState<string | null>(null);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showManualModal, setShowManualModal] = useState(false);
  const [showRevisionModal, setShowRevisionModal] = useState(false);

  const showSidebar = (content: string) => {
    setSidebarContent(content);
    setSidebarVisible(true);
  };

  const hideSidebar = () => {
    setSidebarVisible(false);
    setSidebarContent(null);
  };

  const handleItemClick = (type: string) => {
    if (isSidebarVisible) {
      if (type === "warning-vehicle" && sidebarContent !== "warning-vehicle") {
        showSidebar("warning-vehicle");
      } else if (
        type === "revisoes-periodicas" &&
        sidebarContent !== "revisoes-periodicas"
      ) {
        showSidebar("revisoes-periodicas");
      } else if (
        type === "acoes-oficina" &&
        sidebarContent !== "acoes-oficina"
      ) {
        showSidebar("acoes-oficina");
      } else {
        hideSidebar();
      }
    } else {
      if (type === "warning-vehicle") {
        showSidebar("warning-vehicle");
      } else if (type === "revisoes-periodicas") {
        showSidebar("revisoes-periodicas");
      } else if (type === "acoes-oficina") {
        showSidebar("acoes-oficina");
      }
    }
  };

  const renderSidebarContent = () => {
    if (sidebarContent === "warning-vehicle") {
      return (
        <VehicleAlerts
          closeSidebar={() => handleItemClick("warning-vehicle")}
        />
      );
    } else if (sidebarContent === "revisoes-periodicas") {
      return (
        <PeriodicReviews
          closeSidebar={() => handleItemClick("revisoes-periodicas")}
        />
      );
    } else if (sidebarContent === "acoes-oficina") {
      return (
        <Inspections closeSidebar={() => handleItemClick("acoes-oficina")} />
      );
    }
    return null;
  };

  const handleToggleTableSettingsDialog = () =>
    setShowTableSettingsDialog(!showTableSettingsDialog);
  const handleSearchValueChange = (newSearchValue: string) =>
    setSearchValue(newSearchValue);

  const handleColumnChange = (
    newColumnOrder: string[],
    newHiddenColumns: string[],
    newColumnsDetails = columnsDetails
  ) => {
    setColumnOrder(newColumnOrder);
    setHiddenColumns(newHiddenColumns);
    setColumnsDetails(newColumnsDetails);
    setSecondTableColumns(newColumnsDetails);
  };

  const handleColumnDetailsChange = (
    column: string,
    newColumnDetails: ColumnDetails
  ) => {
    const updatedColumnsDetails = { ...columnsDetails };
    updatedColumnsDetails[column] = newColumnDetails;
    setColumnsDetails(updatedColumnsDetails);
  };

  const handleSortChange = (event: React.MouseEvent<HTMLElement>) => {};

  const renderTableHead = (
    column: string,
    label: string,
    sortByColumn: string
  ) => {
    const tableHeadClassNames = classNames("user-select-none", "sort-column");

    return (
      <th
        key={column}
        className={tableHeadClassNames}
        onClick={handleSortChange}
        data-field={column}
        data-sortby={column}
        title={label}
      >
        <span>
          {sortByColumn === column ? <SortArrows /> : <SortArrows />}
          <span>{label}</span>
        </span>
      </th>
    );
  };

  const renderTableCaption = (column: string, columnDetails: ColumnDetails) => {
    const style = columnDetails?.width
      ? {
          minWidth: columnDetails.width,
          width: columnDetails.width,
        }
      : {};

    return <col key={column} style={style} />;
  };

  const columns = columnOrder.filter((name) => !hiddenColumns.includes(name));

  const withoutHidden = omit(hiddenColumns);
  const filteredRows = vehicleList.map((vehicle) => ({
    ...withoutHidden(vehicle),
  })) as Vehicle[];

  const searchResult = !searchValue
    ? filteredRows
    : filteredRows.filter((row: Partial<Vehicle>) =>
        columns.some((col) => {
          const value = row[col as keyof Vehicle];
          if (value == null) {
            return false;
          }
          row[col as keyof Vehicle]
            ?.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        })
      );

  const rows = sortBy
    ? naturalSortByProperty(searchResult, sortBy as keyof Vehicle)
    : searchResult;

  const tableClassNames = classNames(
    "table",
    "table-layout-fixed",
    "table-column-overflow-hidden",
    "table-bordered",
    "table-head-filled"
  );

  return (
    <div id="TableCommonDemo">
      <TableToolbar>
        <div className="table-toolbar-container">
          <div className="table-toolbar-group-left">
            <TableSearch
              value={searchValue}
              onChange={handleSearchValueChange}
              placeholder="Busque"
            />
          </div>
          <div className="table-toolbar-group-right">
            <div className="table-toolbar-column table-toolbar-column-spacer"></div>
          </div>
          {showTableSettingsDialog && (
            <TableSettingsDialog
              show={showTableSettingsDialog}
              title="Table settings"
              onHide={handleToggleTableSettingsDialog}
              onColumnChange={handleColumnChange}
              defaultColumnOrder={defaultColumnOrder}
              defaultHiddenColumns={[]}
              columnOrder={columnOrder}
              hiddenColumns={hiddenColumns}
              columnLabels={columnLabels}
              disabledColumns={disabledColumns}
              closeButtonText="Close"
              resetButtonText="Reset to default"
              searchPlaceholder="Search by column name"
              notFoundMessage="No column found for this search value"
              columnsDetails={columnsDetails}
              autoLabel="Auto"
              onColumnDetailsChange={handleColumnDetailsChange}
              onSearchChange={(val: string) => console.log(val)}
              immediateChange
            />
          )}
        </div>
      </TableToolbar>
      <div className="tables-row">
        <div className="tables-row__fixed">
          <table className={tableClassNames}>
            <colgroup>
              {columns.map((column) =>
                renderTableCaption(column, columnsDetails[column])
              )}
            </colgroup>
            <thead>
              <tr>
                {columns.map((column) =>
                  renderTableHead(column, columnLabels[column], sortBy)
                )}
              </tr>
            </thead>
            <tbody>
              {rows.map((row: Vehicle, rowIndex: number) => (
                <tr key={rowIndex}>
                  {columns.map((col, colIndex) => (
                    <td
                      key={`${col}-${rowIndex}-${colIndex}`}
                      data-field={columnLabels[col]}
                    >
                      {col === "agendamento" ? (
                        row.agendamento ? (
                          <>
                            <button
                              type="button"
                              onClick={() => setShowScheduleModal(true)}
                            >
                              <span className="rioglyph rioglyph-calendar active" />
                            </button>
                          </>
                        ) : (
                          <span className="rioglyph rioglyph-calendar" />
                        )
                      ) : col === "veiculo" ? (
                        row.veiculo ? (
                          <div className="warning-vehicle">
                            {row.veiculo}
                            <button
                              type="button"
                              className="btn btn-secondary btn-action text-size-h6"
                              onClick={() => handleItemClick("warning-vehicle")}
                            >
                              <span
                                className="rioglyph rioglyph-warning-sign"
                                aria-hidden="true"
                              ></span>
                            </button>
                          </div>
                        ) : (
                          <span className="rioglyph rioglyph-calendar" />
                        )
                      ) : col === "chassi" ? (
                        <button
                          type="button"
                          onClick={() => setShowManualModal(true)}
                        >
                          <span className="chassi-info">{row.chassi}</span>
                        </button>
                      ) : (
                        row[col as keyof Vehicle]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="scrollabe-table">
          <table className={tableClassNames}>
            <colgroup>
              {secondTableColumnOrder.map((column) =>
                renderTableCaption(column, secondTableColumns[column])
              )}
            </colgroup>
            <thead>
              <tr>
                {secondTableColumnOrder.map((column) =>
                  renderTableHead(
                    column,
                    secondTableColumnLabels[column],
                    sortBy
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {secondTableList.map((item, index) => (
                <tr key={index}>
                  {secondTableColumnOrder.map((col) => (
                    <td key={col}>
                      {(() => {
                        if (col === "acoes") {
                          return (
                            item?.acoes && (
                              <div
                                className="acoes-oficina cursor-pointer"
                                onClick={() => handleItemClick("acoes-oficina")}
                              >
                                <span className="rioglyph rioglyph-calendar-today" />
                                <span className="alert-info">3</span>
                              </div>
                            )
                          );
                        }
                        if (col === "revisoes") {
                          return (
                            item.revisoes && (
                              <div className="revisoes-periodicas">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-action text-size-22"
                                  onClick={() =>
                                    handleItemClick("revisoes-periodicas")
                                  }
                                >
                                  <span
                                    className="rioglyph rioglyph-book"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              </div>
                            )
                          );
                        }

                        if (col === "score") {
                          return (
                            <div className="text-center">{item.score}</div>
                          );
                        }

                        if (col === "grupoManutencao") {
                          return (
                            <div className="text-center">
                              {item.grupoManutencao}
                            </div>
                          );
                        }

                        if (col === "status") {
                          return (
                            <div className="">
                              <span
                                className={
                                  item.status === "Online"
                                    ? "rioglyph rioglyph-wifi text-color-status-available padding-right-5"
                                    : item.status === "Offline"
                                    ? "rioglyph rioglyph-wifi-off padding-right-5"
                                    : undefined
                                }
                              ></span>
                              {item.status}
                            </div>
                          );
                        }

                        if (col === "statusGarantia") {
                          return (
                            <div className="status-garantia">
                              <span
                                className={
                                  item.statusGarantia === "Em Garantia"
                                    ? "text-color-garantia"
                                    : "text-color-expirada"
                                }
                              >
                                {item.statusGarantia}
                              </span>
                            </div>
                          );
                        }

                        if (col === "ultimaRevisao") {
                          return (
                            <div className="ultima-revisao">
                              {item.ultimaRevisao &&
                                item.ultimaRevisao.map((rev, index) => (
                                  <div
                                    className="display-flex flex-column"
                                    key={index}
                                  >
                                    <span className="flex items-center justify-between">
                                      {rev.value}
                                      <span
                                        className={
                                          rev.status === "Realizada"
                                            ? "rioglyph-ok-circle text-color-status-available"
                                            : ""
                                        }
                                      ></span>
                                    </span>
                                    <span
                                      className={
                                        rev.status === "Realizada"
                                          ? "text-color-info"
                                          : "text-color-secondary"
                                      }
                                    >
                                      {rev.status}
                                    </span>
                                  </div>
                                ))}
                            </div>
                          );
                        }
                        if (col === "proximaRevisao") {
                          return (
                            <div className="proxima-revisao">
                              {item.proximaRevisao &&
                                item.proximaRevisao.map((rev, index) => (
                                  <div
                                    className="display-flex flex-column"
                                    key={index}
                                  >
                                    <div className="flex items-center justify-between">
                                      <span>{rev.value}</span>
                                      <OverlayTrigger
                                        key={crypto.randomUUID()}
                                        trigger="click"
                                        placement="left"
                                        overlay={
                                          <Tooltip id="tooltip" allowOnTouch>
                                            <div className="tooltip-card flex flex-column padding-10 gap-6">
                                              <h3 className="flex flex-column text-size-18 font-bold items-start text-left">
                                                Revisão de assentamento
                                                <span className="text-size-12 font-normal">
                                                  Dia 18/10/2024 ou 5.000 KM
                                                </span>
                                              </h3>
                                              <ul className="text-left">
                                                <li>Códigos de falha</li>
                                                <li>
                                                  Óleo do motor (nível e
                                                  vazamentos)
                                                </li>
                                                <li>
                                                  Braçadeiras de exaustão do
                                                  turbo compressor
                                                </li>
                                                <li>
                                                  Grampos e suportes das molas
                                                  (inclusive 3º eixo)
                                                </li>
                                              </ul>
                                              <button
                                                className="btn btn-primary"
                                                onClick={() =>
                                                  setShowRevisionModal(true)
                                                }
                                              >
                                                <span>Ver lista completa</span>
                                              </button>
                                            </div>
                                          </Tooltip>
                                        }
                                      >
                                        <span className="cursor-pointer rioglyph rioglyph-exclamation text-size-12 text-color-white icon-revision"></span>
                                      </OverlayTrigger>
                                    </div>
                                    <span
                                      className={
                                        rev.status === "Disponível para agendar"
                                          ? "text-color-success cursor-pointer"
                                          : "text-color-black"
                                      }
                                      onClick={
                                        rev.status === "Disponível para agendar"
                                          ? () => setShowScheduleModal(true)
                                          : undefined
                                      }
                                    >
                                      {rev.status}
                                    </span>
                                  </div>
                                ))}
                            </div>
                          );
                        }
                        return item[col as keyof Product] as React.ReactNode;
                      })()}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Dialog
        show={showRevisionModal}
        title="Detalhes da revisão"
        subtitle="Confira abaixo as informações sobre sua revisão:"
        footer={
          <Button
            onClick={() => setShowRevisionModal(false)}
            className="btn btn-primary"
            bsSize="lg"
          >
            Fechar
          </Button>
        }
        body={<RevisionPlan />}
        onClose={() => setShowRevisionModal(false)}
        className="dialog-agendamento"
        showCloseButton
        bsSize="lg"
      />
      {isSidebarVisible && (
        <div className="sidebar">{renderSidebarContent()}</div>
      )}
      <ScheduleModal
        isOpen={showScheduleModal}
        onClose={() => setShowScheduleModal(false)}
      />
      {showManualModal && (
        <div className="scheduleModal">
          <div className="bg-white shadow-lg scheduleModal__wrapper">
            <div className="flex justify-between items-center scheduleModal__header">
              <button
                onClick={() => setShowManualModal(false)}
                className="text-color-black text-size-h2 hover:text-gray-800"
              >
                &times;
              </button>
            </div>

            {/* Options */}
            <div className="flex flex-column gap-6 scheduleBody">
              <h3 className="text-center text-size-20 font-bold">
                Baixar Manual
              </h3>
              <div className="flex items-center justify-center scheduleBody__card1">
                <img src="assets/images/manual-icon.svg" alt="" />
              </div>
              <div className="border flex items-center gap-6 scheduleBody__card2">
                <span className="rioglyph rioglyph-pdf-file icon"></span>
                <div className="col-r flex flex-column">
                  <span className="text-size-14 font-bold">
                    Manual digital Meteor
                  </span>
                  <span className="text-size-14 font-normal">
                    Documento PDF
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-6 scheduleBody__buttons">
                <button className="btn btn-default text-size-16 btn-scheduling">
                  Abrir
                </button>
                <button className="btn btn-primary text-size-16 btn-scheduling">
                  Salvar como...
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableCommonDemo;
